// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivitiesCardContainer_main__3S4V-{
    width: 200px;
    height: 135px;
    padding: 10px;
    flex-shrink: 0;
}

.ActivitiesCardContainer_activityTitleWrapper__zJGbB h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.ActivitiesCardContainer_desc__JGUZY p{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ActivitiesCardContainer/ActivitiesCardContainer.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".main{\n    width: 200px;\n    height: 135px;\n    padding: 10px;\n    flex-shrink: 0;\n}\n\n.activityTitleWrapper h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    color: #FFFFFF;\n    margin: 0;\n}\n\n.desc p{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    color: #FFFFFF;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ActivitiesCardContainer_main__3S4V-`,
	"activityTitleWrapper": `ActivitiesCardContainer_activityTitleWrapper__zJGbB`,
	"desc": `ActivitiesCardContainer_desc__JGUZY`
};
export default ___CSS_LOADER_EXPORT___;
