// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputField_inputWrapper__xJKUd{
    border: 1px solid #CACACA;
    width: 100%;
    height: 51px;
    padding: 15px;
    gap: 10px;
    border-radius: 10px;
    position: relative;
}

  /* Reset browser-specific styles */
  .InputField_inputWrapper__xJKUd select {
    appearance: none;
    border: none;
    background-color: transparent;
    margin-top: -5px;
  }
  
  /* Remove the blue border on focus */
  .InputField_inputWrapper__xJKUd select:focus {
    outline: none;
    box-shadow: none;
  }

.InputField_inputWrapper__xJKUd input{
   border: none;
   width: 100%;
   background: none;
}

.InputField_inputWrapper__xJKUd input:focus{
    outline: none;
}

.InputField_inputWrapper__xJKUd input::placeholder{
    color: #CACACA;
}

.InputField_inputWrapper__xJKUd label{
    position: absolute;
    top: -12px;
    left: 8px;
    color: #959595;
    background: #FFFFFF;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/InputField/InputField.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,kBAAkB;AACtB;;EAEE,kCAAkC;EAClC;IAGE,gBAAgB;IAChB,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA,oCAAoC;EACpC;IACE,aAAa;IACb,gBAAgB;EAClB;;AAEF;GACG,YAAY;GACZ,WAAW;GACX,gBAAgB;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".inputWrapper{\n    border: 1px solid #CACACA;\n    width: 100%;\n    height: 51px;\n    padding: 15px;\n    gap: 10px;\n    border-radius: 10px;\n    position: relative;\n}\n\n  /* Reset browser-specific styles */\n  .inputWrapper select {\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    border: none;\n    background-color: transparent;\n    margin-top: -5px;\n  }\n  \n  /* Remove the blue border on focus */\n  .inputWrapper select:focus {\n    outline: none;\n    box-shadow: none;\n  }\n\n.inputWrapper input{\n   border: none;\n   width: 100%;\n   background: none;\n}\n\n.inputWrapper input:focus{\n    outline: none;\n}\n\n.inputWrapper input::placeholder{\n    color: #CACACA;\n}\n\n.inputWrapper label{\n    position: absolute;\n    top: -12px;\n    left: 8px;\n    color: #959595;\n    background: #FFFFFF;\n    padding: 0 10px;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 21px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `InputField_inputWrapper__xJKUd`
};
export default ___CSS_LOADER_EXPORT___;
