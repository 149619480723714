import { useNavigate } from 'react-router-dom'
import LessonCardContainer from '../../Components/LessonCardContainer/LessonCardContainer'
import SectionContainer from '../../Components/SectionContainer/SectionContainer'
import CardContainer from '../../Components/UI/CardContainer/CardContainer'
import ViewAllButton from '../../Components/UI/ViewAllButton/ViewAllButton'
import BackIcon from '../../icons/BackIcon'
import BoardIcon from '../../icons/BoardIcon'
import VideoIcon from '../../icons/VideoIcon'
import classes from './Lessons.module.css'

const upcomingLessons = [
    {
        icon: <VideoIcon />,
        type: 'Live lesson',
        name: 'Joseph Myers',
        subject: 'Mathematical Literacy Term 3',
        dateTime: 'Tuesday 16 Feb - 1PM - 2PM',
        backgroundColor: '#E3F0E7',
        color: '#43B061'
    },
    {
        icon: <BoardIcon />,
        type: 'Interactive lesson',
        name: 'Joseph Myers',
        subject: 'NS & Tech: Matter and Materials',
        dateTime: 'Tuesday 16 Feb - 1PM - 2PM',
        backgroundColor: '#F6EEDF',
        color: '#CA9835'
    }
]

const pastLessons = [
    {
        icon: <VideoIcon />,
        type: 'Live lesson',
        name: 'Joseph Myers',
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tuesday 16 Feb - 1PM - 2PM',
        backgroundColor: '#E3F0E7',
        color: '#43B061'
    },
    {
        icon: <VideoIcon />,
        type: 'Live lesson',
        name: 'Joseph Myers',
        subject: 'Mathematical Literacy Term 2',
        dateTime: 'Tuesday 16 Feb - 1PM - 2PM',
        backgroundColor: '#E3F0E7',
        color: '#43B061'
    }
]

const Lessons = () => {
    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.wrapper}>
            <div className={`d-flex align-items-baseline gap-3 mb-4 ${classes.backButton}`} onClick={handleGoBack} role='button'><BackIcon /><h2>Lessons</h2></div>
            <CardContainer className={classes.cardContainer}><h1>You have no lessons scheduled  right now</h1></CardContainer>
            <SectionContainer heading="Upcoming lessons" sort="Show: All types">
                {upcomingLessons.map((item, i) => (<LessonCardContainer key={i} icon={item.icon} name={item.name} subject={item.subject} dateTime={item.dateTime} pillColor={item.color} pillbackgroundColor={item.backgroundColor} lessonType={item.type}/>))}
                <ViewAllButton title="Go to my timetable"/>
            </SectionContainer>
            <SectionContainer heading="Past lessons" sort="Show: All types">
                {pastLessons.map((item, i) => (<LessonCardContainer key={i} icon={item.icon} name={item.name} subject={item.subject} dateTime={item.dateTime} pillColor={item.color} pillbackgroundColor={item.backgroundColor} lessonType={item.type}/>))}
                <ViewAllButton title="Go to my timetable"/>
            </SectionContainer>
            
        </div>
    )
}

export default Lessons