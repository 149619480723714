import { useState } from 'react'
import classes from './AssessmentSubject.module.css'
import SearchBar from '../../../Components/UI/SearchBar/SearchBar'
import CardContainer from '../../../Components/UI/CardContainer/CardContainer'
import HeadingContainer from '../../../Components/UI/HeadingContainer/HeadingContainer'
import { useNavigate } from 'react-router-dom'
import ResourceCardContainer from '../../../Components/ResourceCardContainer/ResourceCardContainer'
import SectionContainer from '../../../Components/SectionContainer/SectionContainer'


const attendanceTableHeader = [
    {
        title: "Lesson name",
        width: 'auto'
    },
    {
        title: "Date",
        width: 'auto'
    },
    {
        title: "Category",
        width: 'auto'
    },
    {
        title: "Grade",
        width: '100px'
    },
    {
        title: "%",
        width: '100px'
    }
]

const attendanceTableContent = [
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "Mathematics",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    }
]

const gradesReceived = [
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#F8C4CD',
        color: '#C36C7B',
        grade: '- -'
    },
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#F8C4CD',
        color: '#C36C7B',
        grade: '- -'
    }
]

const AssessmentSubject = () => {
    const [activeTab, setActiveTab] = useState(0)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.main}>
             <HeadingContainer heading="My assessments / Mathematics" sort="Show: All types" onClick={handleGoBack}/>
            <SearchBar placeholder="Search learners" className="mt-4"/>

            <SectionContainer heading="Assessments not finished (2)" sort="Arrange by: date" >
                {gradesReceived.map((item, i) => (<ResourceCardContainer key={i} subject={item.subject} dateTime={item.dateTime} pillbackgroundColor={item.backgroundColor} pillColor={item.color} grade={item.grade}/>))}
            </SectionContainer>    
                
            
            <SectionContainer heading="Assessments taken (35)" sort="Arrange by: date" >
                <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                    <tr>
                    {attendanceTableHeader.map((item, i) => (
                        <th style={{width: item.width}} key={i}>{item.title}</th>
                    ))}
                    </tr>
                    {attendanceTableContent.map((item, i) => (<tr>
                        <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                        <td>{item.date}</td>
                        <td>{item.category}</td>
                        <td>{item.grade}</td>
                        <td>{item.percentage}</td>
                    </tr>))}
                </table>      
            </SectionContainer>
        </div>
    )
}

export default AssessmentSubject