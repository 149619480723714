// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Sidebar.css */

.LessonDetails_sidebar__69fs7 {
    position: fixed;
    top: 0;
    right: -507px; /* Initially hidden off screen */
    width: 507px;
    border-radius: 20px 0px 0px 20px;
    height: 100%;
    background-color: #F9F9F9;
    /* color: white; */
    padding: 30px 20px 20px 20px;
    transition: right 0.3s ease;
    z-index: 1000;
    box-shadow: 0px 4px 50px 0px #00000066;
  }
  
  .LessonDetails_sidebar__69fs7.LessonDetails_open__r8Bpu {
    right: 0; /* Slide in */
  }
  
  .LessonDetails_closeBtn__ua6Tf {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  
  .LessonDetails_overlay__-OJsO {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.6);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px); /* Apply blur effect */
    z-index: 999; /* Behind the sidebar */
  }

  .LessonDetails_sidebar__69fs7 h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .LessonDetails_lessonTitleWrapper__dr0Ww p{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }
`, "",{"version":3,"sources":["webpack://./src/Components/LessonDetails/LessonDetails.module.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,eAAe;IACf,MAAM;IACN,aAAa,EAAE,gCAAgC;IAC/C,YAAY;IACZ,gCAAgC;IAChC,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;IAC5B,2BAA2B;IAC3B,aAAa;IACb,sCAAsC;EACxC;;EAEA;IACE,QAAQ,EAAE,aAAa;EACzB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;;EAGA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,kCAA0B;YAA1B,0BAA0B,EAAE,sBAAsB;IAClD,YAAY,EAAE,uBAAuB;EACvC;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;EACX","sourcesContent":["/* src/Sidebar.css */\n\n.sidebar {\n    position: fixed;\n    top: 0;\n    right: -507px; /* Initially hidden off screen */\n    width: 507px;\n    border-radius: 20px 0px 0px 20px;\n    height: 100%;\n    background-color: #F9F9F9;\n    /* color: white; */\n    padding: 30px 20px 20px 20px;\n    transition: right 0.3s ease;\n    z-index: 1000;\n    box-shadow: 0px 4px 50px 0px #00000066;\n  }\n  \n  .sidebar.open {\n    right: 0; /* Slide in */\n  }\n  \n  .closeBtn {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    color: white;\n    font-size: 24px;\n    cursor: pointer;\n  }\n  \n  \n  .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(38, 38, 38, 0.6);\n    backdrop-filter: blur(2px); /* Apply blur effect */\n    z-index: 999; /* Behind the sidebar */\n  }\n\n  .sidebar h3 {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    margin: 0;\n  }\n\n  .lessonTitleWrapper p{\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    margin: 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `LessonDetails_sidebar__69fs7`,
	"open": `LessonDetails_open__r8Bpu`,
	"closeBtn": `LessonDetails_closeBtn__ua6Tf`,
	"overlay": `LessonDetails_overlay__-OJsO`,
	"lessonTitleWrapper": `LessonDetails_lessonTitleWrapper__dr0Ww`
};
export default ___CSS_LOADER_EXPORT___;
