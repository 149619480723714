import SectionTitleContainer from '../UI/SectionTitleContainer/SectionTitleContainer'
import classes from './SectionContainer.module.css'

const SectionContainer = ({children, heading, sort, className}) => {
    return(
        <div className={`${classes.main} ${className}`}>
            <SectionTitleContainer heading={heading} sort={sort}/>
            {children}
        </div>
    )
}

export default SectionContainer