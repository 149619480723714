import CardContainer from '../UI/CardContainer/CardContainer'
import IconCard from '../UI/IconCard/IconCard'
import classes from './LessonCardContainer.module.css'

const LessonCardContainer = ({icon, name, subject, dateTime, pillbackgroundColor, pillColor, lessonType}) => {
    return(
        <CardContainer className={classes.main}>
           <div className={`d-flex align-items-center gap-3 ${classes.titleWrapper} mb-3`}>
            <div className={`${classes.pillContainer} d-flex align-items-center gap-2`} style={{backgroundColor: pillbackgroundColor, color: pillColor}}>{icon} {lessonType}</div>
            <h5>{name}</h5>
           </div>
           <h3>{subject}</h3>
           <div className={classes.desc}>
            <h5>{dateTime}</h5>
           </div>
        </CardContainer>
    )
}

export default LessonCardContainer