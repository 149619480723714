import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Lessons from "./pages/Lessons/Lessons";
import Assessments from "./pages/Assessments/Assessments";
import Resources from "./pages/Resources/Resources";
import Points from "./pages/Points/Points";
import Account from "./pages/Account/Account";
import Help from "./pages/Help/Help";
import Performance from "./pages/Performance/Performance";
import Timetable from "./pages/Timetable/Timetable";
import Library from "./pages/Library/Library";
import Login from "./pages/Login/Login";
import { useEffect } from "react";
import Register from "./pages/Register/Register";
import ForgotPin from "./pages/ForgotPin/ForgotPin";
import AllGrades from "./pages/Performance/AllGrades/AllGrades";
import AssessmentSubject from "./pages/Assessments/AssessmentSubject/AssessmentSubject";
import ResourceCategory from "./pages/Resources/ResourceCategory/ReourceCategory";
import ChangeInformation from "./pages/Account/ChangeInformation/ChangeInformation";
import ChangePIN from "./pages/Account/ChangePIN/ChangePIN";
import ParentAccount from "./pages/Account/ParentAccount/ParentAccount";






function App() {
  

  useEffect(() => {
      if (window.location.pathname === "/") {
        window.location = '/login';
      } 
    }, [])
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path="/"  element={<Layout />}>
                <Route index element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/lessons" element={<Lessons />} />
                <Route path="/library" element={<Library />} />
                <Route path="/time-table" element={<Timetable />} />
                <Route path='/assessments' element={<Assessments />}/>
                <Route path='/assessments/assessment-subject' element={<AssessmentSubject />}/>
                <Route path="/performance" element={<Performance />} />
                <Route path="/performance/all-grades" element={<AllGrades />} />
                <Route path='/resources' element={<Resources />}/>
                <Route path="/resources/resource-category" element={<ResourceCategory />} />
                <Route path="/points" element={<Points />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/change-information" element={<ChangeInformation />} />
                <Route path="/account/change-pin" element={<ChangePIN />} />
                <Route path="/account/parent" element={<ParentAccount />} />
                <Route path="/help" element={<Help />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-pin" element={<ForgotPin />} />
          </Routes>
       </BrowserRouter>       
    </div>
  );
}

export default App;
