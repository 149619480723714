import { useNavigate } from "react-router-dom"
import CardContainer from "../../Components/UI/CardContainer/CardContainer"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import SectionTitleContainer from "../../Components/UI/SectionTitleContainer/SectionTitleContainer"
import CameraIcon from "../../icons/CameraIcon"
import classes from './Account.module.css'
import { useState } from "react"
import AlertDialog from "../../Components/UI/AlertDialog/AlertDialog"

const pointsData = [
    {
        title: "Change my information",
        link: "change-information"
    },
    {
        title: "Change my PIN",
        link: "change-pin"
    },
    {
        title: "Parent account",
        link: "parent"
    },
    {
        title: "Copyright notice",
        link: "copyright"
    },
    {
        title: "Terms and conditions",
        link: "terms"
    },
    {
        title: "Close account",
        link: "close-account"
    },
    {
        title: "Log out",
        link: "logout"
    }
]

const Account = () => {

    const [logoutDialog, setLogoutDialog] = useState(false)
    const [closeAccountDialog, setCloseAccountDialog] = useState(false)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.wrapper}>
            <HeadingContainer heading="My account" />
            <div className="mb-4 d-flex align-items-center justify-content-center">
                <div className={`${classes.profileWrapper} d-flex flex-column align-items-center justify-content-center`}>
                    <div className={classes.userImage} style={{backgroundImage: `url(/learner.png)`}}>
                        <div className={`${classes.iconContainer} d-flex align-items-center justify-content-center`}>
                            <CameraIcon />
                        </div>
                    </div>
                    <h3>Jack Williams (ID: 2981882)</h3>
                    <h5>9th grade, School Name, District, Circuit</h5> 
                </div>
            </div>
            {pointsData.map((item, i) => (<CardContainer className={classes.pointsCard} key={i} role="button" onClick={() => item.link === 'logout' ? setLogoutDialog(true) : item.link === 'close-account' ? setCloseAccountDialog(true) :  navigate(item.link)}><div>{item.title}</div></CardContainer>))}
            <AlertDialog isOpen={logoutDialog || closeAccountDialog} titleText={ logoutDialog ? "Log out" : "Close account"} confirmButtonText={logoutDialog ? "Log out" : "Yes, close account"} cancelButtonText={logoutDialog ? "Cancel" : "No, Keep my account"} onCancel={() => logoutDialog ?  setLogoutDialog(false) : setCloseAccountDialog(false)} onConfirm={() => logoutDialog ? navigate('/login') : ""}>
                <div className={`my-4 ${classes.descText}`}>{logoutDialog ? "Are you sure you want to log out of this account?" : "Are you sure you want to close this account?"}</div>
            </AlertDialog>
        </div>
    )
}

export default Account