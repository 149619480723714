import { useNavigate } from "react-router-dom"
import BasicCalendar from "../../Components/BasicCalendar/BasicCalendar"
import ResourceCardContainer from "../../Components/ResourceCardContainer/ResourceCardContainer"
import SectionContainer from "../../Components/SectionContainer/SectionContainer"
import CardContainer from "../../Components/UI/CardContainer/CardContainer"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import InfoCard from "../../Components/UI/InfoCard/InfoCard"
import ViewAllButton from "../../Components/UI/ViewAllButton/ViewAllButton"
import BackIcon from "../../icons/BackIcon"
import classes from "./Performance.module.css"

const gradesReceived = [
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#DFF0FF',
        color: '#76A3CC',
        grade: '(A+) 97%'
    },
    {
        subject: 'Mathematical Literacy Term 2',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#DFF0FF',
        color: '#76A3CC',
        grade: '(A+) 97%'
    },
    {
        subject: 'Mathematical Literacy Term 2',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#DFF0FF',
        color: '#76A3CC',
        grade: '(A+) 97%'
    }
]

const statisticsData = [
    {
        title: 'Average grade:',
        titleValue: 'B+'
    },
    {
        title: 'Attendance:',
        titleValue: '98%'
    },
    {
        title: 'Performance:',
        titleValue: 'Improving'
    }
]

const receivedGrades = [
    {
        subject: "Mathematical Literacy Term 1",
        grade: "A+",
        percentage: "97%"
    },
    {
        subject: "Mathematical Literacy Term 1",
        grade: "A+",
        percentage: "97%"
    },
    {
        subject: "Mathematical Literacy Term 1",
        grade: "A+",
        percentage: "97%"
    }
]
const Performance = () => {


    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
    <div className={classes.wrapper}>
        <HeadingContainer heading="My performance"  className={classes.innerWrapper} />
        <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3`}>
            {statisticsData.map((item, i) => (<InfoCard title={item.title} buttonText="View details" titleValue={item.titleValue} color="#FFFFFF" backgroundColor="#76A3CC" key={i}/>))}
        </div>
        <SectionContainer heading="Grades received" sort="Arrange by: date" className={classes.innerWrapper}>
           {gradesReceived.map((item, i) => (<ResourceCardContainer key={i} subject={item.subject} dateTime={item.dateTime} pillbackgroundColor={item.backgroundColor} pillColor={item.color} grade={item.grade}/>))}
           <ViewAllButton title="View all grades" className="mt-4" onClick={() => navigate('all-grades')}/>
        </SectionContainer>
        <div className={`row mt-5 ${classes.innerWrapper}`}>
            <div className="col-6">
                <BasicCalendar />
            </div>
            <div className={`${classes.receivedWrapper} col-6`}>
                <h6>Grades received</h6>
                {receivedGrades.map((item, i) => (<CardContainer className={`d-flex align-items-center justify-content-between mt-3 ${classes.receivedCard}`} key={i}>
                    <div>
                        {item.subject}
                    </div>
                    <div>
                    ({item.grade}) {item.percentage}
                    </div>
                </CardContainer>))}
            </div>
        </div>
    </div>
    )
}

export default Performance