import { useState } from 'react'
import classes from './ChangeInformation.module.css'
import HeadingContainer from '../../../Components/UI/HeadingContainer/HeadingContainer'
import { useNavigate } from 'react-router-dom'
import ConfirmButton from '../../../Components/UI/ConfirmButton/ConfirmButton'
import InputField from '../../../Components/UI/InputField/InputField'


const attendanceTableHeader = [
    {
        title: "Lesson name",
        width: 'auto'
    },
    {
        title: "Date",
        width: 'auto'
    },
    {
        title: "Category",
        width: 'auto'
    },
    {
        title: "Grade",
        width: '100px'
    },
    {
        title: "%",
        width: '100px'
    }
]

const attendanceTableContent = [
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    }
]

const ChangeInformation = () => {
    const [activeTab, setActiveTab] = useState(0)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.main}>
             <HeadingContainer heading="My account / Change my information"  onClick={handleGoBack}/>
             <div className={`d-flex flex-column mt-5 gap-5`} >
                <InputField label="First name*" type="text" labelBackground="#F9F9F9"/>
                <InputField label="Last name*" type="text" labelBackground="#F9F9F9"/>
                <InputField label="Email*" type="email" labelBackground="#F9F9F9"/>
                <InputField label="Phone*" type="number" labelBackground="#F9F9F9"/>
                <InputField label="School name" type="text" labelBackground="#F9F9F9"/>
                <InputField label="District" type="text" labelBackground="#F9F9F9"/>
                <InputField label="Circuit" type="text" labelBackground="#F9F9F9"/>
                <InputField label="Grade" type="text" labelBackground="#F9F9F9"/>
                <ConfirmButton buttonText="Save changes" />
            </div>
        </div>
    )
}

export default ChangeInformation