import CardContainer from "../../Components/UI/CardContainer/CardContainer"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import SectionTitleContainer from "../../Components/UI/SectionTitleContainer/SectionTitleContainer"
import classes from './Points.module.css'

const pointsData = [
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    },
    {
        title: "Action taken",
        points: "+ 20 points"
    }
]

const Points = () => {
    return(
        <div className={classes.wrapper}>
            <HeadingContainer heading="My points" sort="Show: All types"/>
            <CardContainer className={classes.cardContainer}><h1>You have a total of 3,780 points</h1></CardContainer>
            <SectionTitleContainer heading="Points activity" sort="Arrange by: date" className="mt-4"/>
            {pointsData.map((item, i) => (<CardContainer className={classes.pointsCard} key={i}><div className="d-flex align-items-center justify-content-between"><div>{item.title}</div><div>{item.points}</div></div></CardContainer>))}
        </div>
    )
}

export default Points