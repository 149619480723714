import CardContainer from '../UI/CardContainer/CardContainer'
import classes from './PerformanceCardContainer.module.css'

const PerformanceCardContainer = ({title, subject, date, borderColor}) => {
    return(
        <CardContainer className={classes.main} >
           <div className={`d-flex  gap-3 ${classes.titleWrapper}`}>
            <div className={classes.verticalBorder} style={{border: `2px solid ${borderColor}`}}></div>
            <h3>{title}</h3>
           </div>
           <div className={classes.desc}>
            <p>{subject}</p>
            <p>{date}</p>
           </div>
        </CardContainer>
    )
}

export default PerformanceCardContainer