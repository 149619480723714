// src/Sidebar.js

import React, { Children } from 'react';
import classes from './AlertDialog.module.css'

import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../../icons/CloseIcon';




const AlertDialog = ({ isOpen, toggleSidebar, children, titleText, confirmButtonText, cancelButtonText, onCancel, onConfirm }) => {
    const navigate = useNavigate();


    return (
      <>
        {isOpen && <div className={classes.overlay} onClick={toggleSidebar}></div>}
        <div className={`${classes.dialogBox} ${isOpen ? classes.open : ''}`}>
            <div className='d-flex align-items-center justify-content-between'><div className={classes.titleText}>{titleText}</div> <CloseIcon onClick={onCancel}/></div>
            {children}
            <div className='d-flex align-items-center justify-content-between gap-3'><div className={classes.confirmButton} role='button' onClick={onConfirm}>{confirmButtonText}</div><div className={classes.cancelButton} role='button' onClick={onCancel}>{cancelButtonText}</div></div>
        </div>
      </>
    );
  };
  
  export default AlertDialog;