const DocumentIcon = ({size}) => {
    return(
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9.29686H17.8362V3.9098L14.0453 0H0V24H17.8362V20.3812H22V9.29686ZM20.6373 18.9749H4.26979V10.7031H20.6373V18.9749ZM1.3627 22.5938V1.40625H12.5974V5.40628H16.4735V9.29691H2.90709V20.3812H16.4735V22.5938H1.3627ZM13.9601 1.90022L15.9959 3.99998H13.9601V1.90022Z" fill="#AF80CC"/>
        <path d="M4.50445 5.29688H8.91805V6.70312H4.50445V5.29688Z" fill="#AF80CC"/>
        <path d="M7.46019 12.3784H5.82172L5.82354 17.3023C5.82354 17.3023 7.2238 17.3039 7.50038 17.2988C8.66894 17.2778 9.51713 16.2392 9.51713 14.839C9.51709 13.3902 8.67126 12.3784 7.46019 12.3784ZM7.47654 15.8858C7.40318 15.8871 7.29707 15.8883 7.18151 15.8893C7.18042 15.5115 7.17865 14.1786 7.17806 13.7846H7.46019C8.08671 13.7846 8.15439 14.522 8.15439 14.839C8.15439 15.354 7.94481 15.8774 7.47654 15.8858Z" fill="#AF80CC"/>
        <path d="M15.6221 14.839C15.6221 16.2254 16.715 17.3532 18.0584 17.3532C18.6023 17.3532 19.092 17.1673 19.4749 16.8155C19.5497 16.7467 19.6208 16.671 19.6861 16.5907L18.6421 15.687C18.619 15.7154 18.5942 15.7418 18.5685 15.7654C18.4356 15.8876 18.2687 15.947 18.0585 15.947C17.4664 15.947 16.9848 15.4499 16.9848 14.839C16.9848 14.2281 17.4665 13.731 18.0585 13.731C18.2737 13.731 18.4811 13.7963 18.6583 13.9198L19.4211 12.7546C19.0178 12.4734 18.5466 12.3248 18.0585 12.3248C16.715 12.3248 15.6221 13.4527 15.6221 14.839Z" fill="#AF80CC"/>
        <path d="M12.582 12.3248C11.2385 12.3248 10.1456 13.4527 10.1456 14.839C10.1456 16.2254 11.2386 17.3533 12.582 17.3533C13.9254 17.3533 15.0183 16.2254 15.0183 14.839C15.0183 13.4527 13.9254 12.3248 12.582 12.3248ZM12.582 15.947C11.9899 15.947 11.5083 15.45 11.5083 14.839C11.5083 14.2281 11.9899 13.731 12.582 13.731C13.174 13.731 13.6556 14.2281 13.6556 14.839C13.6556 15.45 13.174 15.947 12.582 15.947Z" fill="#AF80CC"/>
        </svg>
    )
}

export default DocumentIcon