// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardContainer_wrapper__QQE0y{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    /* padding: 15px 20px 15px 20px; */
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/CardContainer/CardContainer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,kCAAkC;IAClC,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB","sourcesContent":[".wrapper{\n    width: 100%;\n    height: fit-content;\n    /* padding: 15px 20px 15px 20px; */\n    border-radius: 10px;\n    box-shadow: 0px 2px 2px 0px #0000000D;\n    background: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CardContainer_wrapper__QQE0y`
};
export default ___CSS_LOADER_EXPORT___;
