// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParentAccount_main__K7PDD{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 30px;
}

.ParentAccount_requestsContainer__ipKZ1 h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.ParentAccount_requestsContainer__ipKZ1 h3{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
}

.ParentAccount_requestsContainer__ipKZ1 button{
    width: 115.73px;
    height: 51px;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: white;
    border: none;
}

.ParentAccount_imageContainer__7pdKv{
    width: 80px;
    height: 80px;
    border-radius: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/ParentAccount/ParentAccount.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,4BAA4B;IAC5B,sBAAsB;IACtB,kCAAkC;AACtC","sourcesContent":[".main{\n    height: calc(100vh - 80px);\n    overflow-y: auto;\n    padding: 40px 30px;\n}\n\n.requestsContainer h4{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n}\n\n.requestsContainer h3{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n    text-align: left;\n}\n\n.requestsContainer button{\n    width: 115.73px;\n    height: 51px;\n    border-radius: 10px;\n    background-color: var(--primary-color);\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 21px;\n    text-align: center;\n    color: white;\n    border: none;\n}\n\n.imageContainer{\n    width: 80px;\n    height: 80px;\n    border-radius: 150px;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ParentAccount_main__K7PDD`,
	"requestsContainer": `ParentAccount_requestsContainer__ipKZ1`,
	"imageContainer": `ParentAccount_imageContainer__7pdKv`
};
export default ___CSS_LOADER_EXPORT___;
