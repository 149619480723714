// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.IconCard_iconWrapper__KFUL3{
    box-shadow: 0px 2px 5px 0px #00000026;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    background-color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/IconCard/IconCard.module.css"],"names":[],"mappings":";AACA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":["\n.iconWrapper{\n    box-shadow: 0px 2px 5px 0px #00000026;\n    border-radius: 10px;\n    height: 40px;\n    width: 40px;\n    background-color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": `IconCard_iconWrapper__KFUL3`
};
export default ___CSS_LOADER_EXPORT___;
