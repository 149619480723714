// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Help_wrapper__Q0uCi {
    padding-bottom: 30px;
}
.Help_innerWrapper__eQiQW{
    padding: 0 30px;
}



.Help_wrapper__Q0uCi h1{
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    margin: 0;
}


.Help_cardContainer__wtIhK{
    padding: 15px;
    border-radius: 20px;
}

.Help_timetableButton__0E8yn{
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.Help_statisticsContainer__gFVS4{
    width: 100vw;
    overflow-x: auto;
    padding: 0 30px;
}

.Help_infoCard__HPseo{
    width: 222px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Help/Help.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB;;;;AAIA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".wrapper {\n    padding-bottom: 30px;\n}\n.innerWrapper{\n    padding: 0 30px;\n}\n\n\n\n.wrapper h1{\n    font-size: 20px;\n    font-weight: 275;\n    line-height: 30px;\n    text-align: center;\n    margin: 0;\n}\n\n\n.cardContainer{\n    padding: 15px;\n    border-radius: 20px;\n}\n\n.timetableButton{\n    width: fit-content;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: #43B061;\n}\n\n.statisticsContainer{\n    width: 100vw;\n    overflow-x: auto;\n    padding: 0 30px;\n}\n\n.infoCard{\n    width: 222px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Help_wrapper__Q0uCi`,
	"innerWrapper": `Help_innerWrapper__eQiQW`,
	"cardContainer": `Help_cardContainer__wtIhK`,
	"timetableButton": `Help_timetableButton__0E8yn`,
	"statisticsContainer": `Help_statisticsContainer__gFVS4`,
	"infoCard": `Help_infoCard__HPseo`
};
export default ___CSS_LOADER_EXPORT___;
