// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmButton_continueButton__Ku58y{
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
    color: #FFFFFF;
    background: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/ConfirmButton/ConfirmButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,gCAAgC;AACpC","sourcesContent":[".continueButton{\n    width: 100%;\n    padding: 20px 0px;\n    border-radius: 10px;\n    color: #FFFFFF;\n    background: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"continueButton": `ConfirmButton_continueButton__Ku58y`
};
export default ___CSS_LOADER_EXPORT___;
