import { useState } from 'react'
import classes from './Login.module.css'
import BackIcon from '../../icons/BackIcon'
import { useNavigate } from 'react-router-dom'
import InputField from '../../Components/UI/InputField/InputField'
import { useAuth } from '../../context/AuthContext'


const Login = () => {
    const [showReset, setShowReset] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")

    let navigate = useNavigate();
    // const { fetchLessons } = useContext(LessonsContext);



  //   const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const { handleLogin } = useAuth();
  // const history = useHistory();

  const onSubmit = async () => {
    
    // try {
    //   await handleLogin(email, password);
    //   navigate('/');
    // } catch (error) {
    //   console.error('Login failed', error);
    // }
  };




   
    
    return(
        <div className={`d-flex flex-column align-items-center justify-content-between ${classes.main}`}>

            <img src='/thuto-login.svg' />
            
            <div className={`d-flex flex-column mt-5 ${classes.inputWrapper}`}>
              
                
                <div className={`d-flex flex-column gap-5`} >
                    <InputField label="Your ID*" type="text" placeholder="Enter Your ID"/>
                    <InputField label="Your PIN*" type="number" placeholder="Enter Your PIN"/>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-4'><div className={classes.linkText} onClick={() => navigate('/register')} role='button'>Register new account</div><div className={classes.linkText} onClick={() => navigate('/forgot-pin')} role='button'>Forgot my Password</div></div>
            </div>

            <div className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`} onClick={() => navigate('/')} role='button'>
                Continue
              </div>
           
        </div>
    )
}

export default Login