// src/MyCalendar.js
import React, {useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from './Components/CustomToolbar/CustomToolbar';
import DayViewCustomEvent from './Components/CustomEvent/DayViewCustomEvent';
import CustomToolbarComponent from './Components/CustomToolbarComponent/CustomToolbarComponent';



const localizer = momentLocalizer(moment);



const CalendarComponent = ({showMonth, className, toggleDetails}) => {



  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    // Do something with the event
    toggleDetails()
  };
  
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();


  const events = [
    {
      title: 'Chemistry',
      start: new Date(2024, month, day, 18, 0),
      end: new Date(2024, month, day, 19, 0),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 1',
      start: new Date(2024,month, day, 14, 0),
      end: new Date(2024, month, day, 15, 0),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 3',
      start: new Date(2024, month, day, 0, 0),
      end: new Date(2024, month, day, 1, 0),
      allDay: false,
    },
  ];

  const [myEvents, setMyEvents] = useState(events);



  return (
    <div className={`custom-calendar ${className}`}>
    <Calendar
      localizer={localizer}
      events={events}
      // startAccessor="start"
      // endAccessor="end"
      // style={{ height: 500 }}
      views={['month', 'week', 'day']}  // Exclude 'agenda' from the views
      components={{
        toolbar: showMonth ? CustomToolbarComponent : CustomToolbar ,  // Use custom toolbar
        day: {
          event: DayViewCustomEvent  // Use custom event component for day view only
        },
      }}
      onSelectEvent={handleEventClick}
    />
  </div>
  );
};

export default CalendarComponent