const PerformanceIcon = ({active}) => {
    return(
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.75 0C1.24009 0 0 1.35283 0 3V7.71429C4.71428e-05 8.18765 0.351795 8.57138 0.785713 8.57143H3.92856V21C3.92856 23.2524 5.52032 24 6.67703 24H18.8571C20.5836 24 22 22.4548 22 20.5714V18C22 17.5266 21.6482 17.1429 21.2143 17.1429H19.6429V2.57143C19.6429 1.16168 18.578 0 17.2857 0C12.2412 0.000201429 7.10858 6.85714e-06 2.75 0ZM2.75 1.71429C3.41652 1.71429 3.92856 2.27289 3.92856 3V6.85714H1.57143V3C1.57143 2.27289 2.08347 1.71429 2.75 1.71429ZM5.22069 1.71429H17.2857C17.7346 1.71429 18.0714 2.08175 18.0714 2.57143V17.1429H8.64284C8.20892 17.1429 7.85718 17.5266 7.85713 18V21C7.85713 21.8506 7.39863 22.2857 6.67856 22.2857C6.01204 22.2857 5.49999 21.7271 5.49999 21V3C5.49999 2.53873 5.39389 2.10615 5.22069 1.71429ZM9.42855 18.8571H20.4285V20.5714C20.4285 21.5284 19.7343 22.2857 18.8571 22.2857C15.6212 22.2857 12.3852 22.2857 9.14926 22.2857C9.35044 21.8522 9.42855 21.4311 9.42855 21V18.8571Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M8.64284 4.28571C8.53966 4.28571 8.43749 4.30788 8.34216 4.35096C8.24683 4.39403 8.16022 4.45717 8.08726 4.53676C8.0143 4.61636 7.95642 4.71085 7.91694 4.81484C7.87745 4.91884 7.85713 5.0303 7.85713 5.14286C7.85713 5.25542 7.87745 5.36688 7.91694 5.47087C7.95642 5.57487 8.0143 5.66936 8.08726 5.74895C8.16022 5.82854 8.24683 5.89168 8.34216 5.93475C8.43749 5.97783 8.53966 6 8.64284 6H14.9285C15.0317 6 15.1339 5.97783 15.2292 5.93475C15.3246 5.89168 15.4112 5.82854 15.4841 5.74895C15.5571 5.66936 15.615 5.57487 15.6545 5.47087C15.6939 5.36688 15.7143 5.25542 15.7143 5.14286C15.7143 5.0303 15.6939 4.91884 15.6545 4.81484C15.615 4.71085 15.5571 4.61636 15.4841 4.53676C15.4112 4.45717 15.3246 4.39403 15.2292 4.35096C15.1339 4.30788 15.0317 4.28571 14.9285 4.28571H8.64284Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M8.64284 8.57143C8.53966 8.57143 8.43749 8.5936 8.34216 8.63667C8.24683 8.67975 8.16022 8.74289 8.08726 8.82248C8.0143 8.90207 7.95642 8.99656 7.91694 9.10056C7.87745 9.20455 7.85713 9.31601 7.85713 9.42857C7.85713 9.54113 7.87745 9.65259 7.91694 9.75659C7.95642 9.86058 8.0143 9.95507 8.08726 10.0347C8.16022 10.1143 8.24683 10.1774 8.34216 10.2205C8.43749 10.2635 8.53966 10.2857 8.64284 10.2857H14.9285C15.0317 10.2857 15.1339 10.2635 15.2292 10.2205C15.3246 10.1774 15.4112 10.1143 15.4841 10.0347C15.5571 9.95507 15.615 9.86058 15.6545 9.75659C15.6939 9.65259 15.7143 9.54113 15.7143 9.42857C15.7143 9.31601 15.6939 9.20455 15.6545 9.10056C15.615 8.99656 15.5571 8.90207 15.4841 8.82248C15.4112 8.74289 15.3246 8.67975 15.2292 8.63667C15.1339 8.5936 15.0317 8.57143 14.9285 8.57143H8.64284Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M8.64284 12.8571C8.53966 12.8571 8.43749 12.8793 8.34216 12.9224C8.24683 12.9655 8.16022 13.0286 8.08726 13.1082C8.0143 13.1878 7.95642 13.2823 7.91694 13.3863C7.87745 13.4903 7.85713 13.6017 7.85713 13.7143C7.85713 13.8268 7.87745 13.9383 7.91694 14.0423C7.95642 14.1463 8.0143 14.2408 8.08726 14.3204C8.16022 14.4 8.24683 14.4631 8.34216 14.5062C8.43749 14.5493 8.53966 14.5714 8.64284 14.5714H14.9285C15.0317 14.5714 15.1339 14.5493 15.2292 14.5062C15.3246 14.4631 15.4112 14.4 15.4841 14.3204C15.5571 14.2408 15.615 14.1463 15.6545 14.0423C15.6939 13.9383 15.7143 13.8268 15.7143 13.7143C15.7143 13.6017 15.6939 13.4903 15.6545 13.3863C15.615 13.2823 15.5571 13.1878 15.4841 13.1082C15.4112 13.0286 15.3246 12.9655 15.2292 12.9224C15.1339 12.8793 15.0317 12.8571 14.9285 12.8571H8.64284Z" fill={active ? "#43B061" : "#262626"}/>
        </svg>
    )
}

export default PerformanceIcon