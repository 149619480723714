// src/CustomToolbar.js
import React, { useEffect, useState } from 'react';
import classes from './CustomToolbar.module.css'
import ForwardIcon from '../../../../icons/ForwardIcon';
import { getDateTime } from '../../../../utilities/functions';


const NavButton = (right, onClick) => {
    return(
        <div className={`${classes.navButton} d-flex align-items-center justify-content-center`} onClick={onClick}>
            <ForwardIcon right={right} />
        </div>
    )
}

const CustomToolbar = (toolbar) => {

   var {day, date, month, year, hours, minutes, period} = getDateTime()

   



    useEffect(() => {
        toolbar.onView('day')
    },[])

    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };

    const goToCurrent = () => {
        toolbar.onNavigate('TODAY');
    };

    const label = () => {
        const date = toolbar.date;
        const view = toolbar.view;
        const label = toolbar.label;
        return <span>{label}</span>;
    };

  return (
    <div> 
         <div className="d-flex align-items-center justify-content-between gap-3 mb-4">
            <div>
                <div className={classes.dateText}>{day}, {date} {month}, {year} {hours}:{minutes < 10 ? '0' : ''}{minutes} {period}</div>
                <div className={classes.classesText}>Today you have 5 classes and you missed 1</div>
            </div>
            <div className="rbc-toolbar d-flex align-items-center justify-content-end ">
                <div className={`${classes.viewButtonsWrapper} d-flex align-items-center`}>
                    <div className={`d-flex align-items-center justify-content-center ${classes.dayButton}`} style={{color: toolbar.view === "day" && "#FFFFFF", background: toolbar.view === "day" && "#43B061"}} onClick={() => { toolbar.onView('day'); }} role='button'>
                        Day
                    </div>
                    <div className={`d-flex align-items-center justify-content-center ${classes.weekButton}`} style={{color: toolbar.view === "week" && "#FFFFFF", background: toolbar.view === "week" && "#43B061"}} onClick={() => { toolbar.onView('week'); }} role='button'>
                        Week
                    </div>
                    <div className={`d-flex align-items-center justify-content-center ${classes.monthButton}`} style={{color: toolbar.view === "month" && "#FFFFFF", background: toolbar.view === "month" && "#43B061"}} onClick={() => { toolbar.onView('month'); }} role='button'>
                        Month
                    </div>

                </div>
            </div>
        </div>
        {/* <div className='d-flex align-items-center justify-content-between mb-4'> 
            <div className={`${classes.navButton} d-flex align-items-center justify-content-center`} onClick={goToBack} role='button'>
                <ForwardIcon />
            </div>
            <div className={classes.dateText}>{label()}</div>
            <div className={`${classes.navButton} d-flex align-items-center justify-content-center`} onClick={goToNext} role='button'>
                <ForwardIcon right={true}/>
            </div>
        </div> */}
    </div>
  );
};

export default CustomToolbar;