import { useNavigate } from "react-router-dom";
import classes from "./ParentAccount.module.css";
import HeadingContainer from "../../../Components/UI/HeadingContainer/HeadingContainer";
import ConfirmButton from "../../../Components/UI/ConfirmButton/ConfirmButton";


const parentsConnected = [
    {
        image: '/parent-1.png',
        name: "Parent name",
        email: "parentemail@gmail.com",
        buttonText: "Disconnect"
    }
]

const parentsRequests = [
    {
        image: '/parent-2.png',
        name: "Parent name",
        email: "parentemail@gmail.com",
        buttonText: "Manage"
    }
]


const RequestContainer = ({title, requestList}) => {
    return(
        <div className={classes.requestsContainer}>
          <h4>Connected accounts</h4>
          {requestList.map((item, i) => (<div className="d-flex align-items-center justify-content-between mt-4" key={i}>
            <div className="d-flex align-items-center gap-3">
              <div
                className={classes.imageContainer}
                style={{ backgroundImage: `url(${item?.image})` }}
              >
                
              </div>
              <div>
                  <h3 className="m-0">{item?.name}</h3>
                  <h4 className="m-0">{item?.email}</h4>
                </div>
            </div>
            <button>{item.buttonText}</button>
          </div>))}
        </div>
    )
}

const ParentAccount = () => {
  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={classes.main}>
      <HeadingContainer
        heading="My account / Parent account"
        onClick={handleGoBack}
      />
      <div className={`d-flex flex-column mt-5 gap-5`}>
        <RequestContainer title="Connected accounts" requestList={parentsConnected}/>
        <RequestContainer title="Requests" requestList={parentsRequests}/>
        <ConfirmButton buttonText="Add new parent account" />
      </div>
    </div>
  );
};

export default ParentAccount;
