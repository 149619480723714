const DashboardIcon = ({active}) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.90625 12.1875H2.34375C1.05141 12.1875 0 11.1361 0 9.84375V2.34375C0 1.05141 1.05141 0 2.34375 0H3.90625C5.19859 0 6.25 1.05141 6.25 2.34375V9.84375C6.25 11.1361 5.19859 12.1875 3.90625 12.1875ZM2.34375 1.5625C1.91297 1.5625 1.5625 1.91297 1.5625 2.34375V9.84375C1.5625 10.2745 1.91297 10.625 2.34375 10.625H3.90625C4.33703 10.625 4.6875 10.2745 4.6875 9.84375V2.34375C4.6875 1.91297 4.33703 1.5625 3.90625 1.5625H2.34375Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M9.84375 20H2.34375C1.05141 20 0 18.9486 0 17.6562V16.0938C0 14.8014 1.05141 13.75 2.34375 13.75H9.84375C11.1361 13.75 12.1875 14.8014 12.1875 16.0938V17.6562C12.1875 18.9486 11.1361 20 9.84375 20ZM2.34375 15.3125C1.91297 15.3125 1.5625 15.663 1.5625 16.0938V17.6562C1.5625 18.087 1.91297 18.4375 2.34375 18.4375H9.84375C10.2745 18.4375 10.625 18.087 10.625 17.6562V16.0938C10.625 15.663 10.2745 15.3125 9.84375 15.3125H2.34375Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M17.6562 20H16.0938C14.8014 20 13.75 18.9486 13.75 17.6562V16.0938C13.75 14.8014 14.8014 13.75 16.0938 13.75H17.6562C18.9486 13.75 20 14.8014 20 16.0938V17.6562C20 18.9486 18.9486 20 17.6562 20ZM16.0938 15.3125C15.663 15.3125 15.3125 15.663 15.3125 16.0938V17.6562C15.3125 18.087 15.663 18.4375 16.0938 18.4375H17.6562C18.087 18.4375 18.4375 18.087 18.4375 17.6562V16.0938C18.4375 15.663 18.087 15.3125 17.6562 15.3125H16.0938Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M17.6562 12.1875H10.1562C8.86391 12.1875 7.8125 11.1361 7.8125 9.84375V2.34375C7.8125 1.05141 8.86391 0 10.1562 0H17.6562C18.9486 0 20 1.05141 20 2.34375V9.84375C20 11.1361 18.9486 12.1875 17.6562 12.1875ZM10.1562 1.5625C9.72547 1.5625 9.375 1.91297 9.375 2.34375V9.84375C9.375 10.2745 9.72547 10.625 10.1562 10.625H17.6562C18.087 10.625 18.4375 10.2745 18.4375 9.84375V2.34375C18.4375 1.91297 18.087 1.5625 17.6562 1.5625H10.1562Z" fill={active ? "#43B061" : "#262626"}/>
        </svg>
    )
}

export default DashboardIcon