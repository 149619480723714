import classes from './IconContainer.module.css'

const IconContainer = ({children, onClick}) =>{
    return(
        <div className={`${classes.iconContainer} d-flex align-items-center justify-content-center`} onClick={onClick} role="button">
            {children}
        </div>
    )
}

export default IconContainer