import ResourceCardContainer from "../../Components/ResourceCardContainer/ResourceCardContainer"
import SectionContainer from "../../Components/SectionContainer/SectionContainer"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import InfoCard from "../../Components/UI/InfoCard/InfoCard"
import SearchBar from "../../Components/UI/SearchBar/SearchBar"
import ViewAllButton from "../../Components/UI/ViewAllButton/ViewAllButton"
import classes from "./Help.module.css"

const gradesReceived = [
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#EFF0F1',
        color: '#959595'
    },
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#EFF0F1',
        color: '#959595'
    },
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#EFF0F1',
        color: '#959595'
    },
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#EFF0F1',
        color: '#959595'
    }
]

const statisticsData = [
    {
        title: 'Mathematics',
        buttonText: '2 assessments'
    },
    {
        title: 'Geography',
        buttonText: '6 not finished'
    },
    {
        title: 'English',
        buttonText: '2 not finished'
    },
    {
        title: 'English',
        buttonText: '2 not finished'
    }
]

const statisticsData1 = [
    {
        title: 'Biology',
        buttonText: '2 assessments'
    },
    {
        title: 'Adv. Mathematics',
        buttonText: '15 assessments'
    },
    {
        title: 'Physics',
        buttonText: '2 not finished'
    },
]

const Help = () => {
    return(
    <div className={classes.wrapper}>
        <div className={classes.innerWrapper}>
        <HeadingContainer heading="Help section" sort="Show: All types" />
        <SearchBar className={`mb-5`} placeholder="Search in help section"/>
        </div>
        
        <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3 mb-3`}>
            {statisticsData.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText}   key={i} className={classes.infoCard}/>))}
        </div>
        <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3`}>
            {statisticsData1.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText}  key={i} className={classes.infoCard}/>))}
        </div>
        <SectionContainer heading="Help articles" sort="Arrange by: date" className={classes.innerWrapper}>
           {gradesReceived.map((item, i) => (<ResourceCardContainer key={i} subject={item.subject} dateTime={item.dateTime} pillbackgroundColor={item.backgroundColor} pillColor={item.color}/>))}
        </SectionContainer>
    </div>
    )
}

export default Help