import classes from './IconCard.module.css'

const IconCard = ({children}) =>{
    return(
        <div className={`${classes.iconWrapper} d-flex  justify-content-center align-items-center`}>
            {children}
        </div>
    )
}

export default IconCard