// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionTitleContainer_titleWrapper__AERiO {
    /* border-left: 3px solid #76A3CC; */
    margin-bottom: 23px;
}

.SectionTitleContainer_titleWrapper__AERiO h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.SectionTitleContainer_showTypes__C19CS{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/SectionTitleContainer/SectionTitleContainer.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".titleWrapper {\n    /* border-left: 3px solid #76A3CC; */\n    margin-bottom: 23px;\n}\n\n.titleWrapper h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    margin: 0;\n}\n\n.showTypes{\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": `SectionTitleContainer_titleWrapper__AERiO`,
	"showTypes": `SectionTitleContainer_showTypes__C19CS`
};
export default ___CSS_LOADER_EXPORT___;
