// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoCard_main__nJKgI{
    padding: 20px;
    width: -moz-fit-content;
    width: fit-content;
    height: 100px;
    flex-shrink: 0;
}

.InfoCard_main__nJKgI h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.InfoCard_main__nJKgI h3 span{
    font-weight: 700
}

.InfoCard_pillContainer__vvH5O{
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    gap: 10px;
    border-radius: 100px;
    background: #E3F0E7;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
}

.InfoCard_gradeText__SBlA7{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.InfoCard_viewDetailsButton__ZaGCB{
    width: -moz-fit-content;
    width: fit-content;
}

.InfoCard_viewDetailsButton__ZaGCB span{
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-color);
    line-height: 18px;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/InfoCard/InfoCard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI;AACJ;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;IACT,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,iBAAiB;IACjB,0BAA0B;AAC9B","sourcesContent":[".main{\n    padding: 20px;\n    width: fit-content;\n    height: 100px;\n    flex-shrink: 0;\n}\n\n.main h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    margin: 0;\n}\n\n.main h3 span{\n    font-weight: 700\n}\n\n.pillContainer{\n    width: fit-content;\n    padding: 3px 10px;\n    gap: 10px;\n    border-radius: 100px;\n    background: #E3F0E7;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 15px;\n}\n\n.gradeText{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n}\n\n.viewDetailsButton{\n    width: fit-content;\n}\n\n.viewDetailsButton span{\n    font-size: 12px;\n    font-weight: 600;\n    color: var(--primary-color);\n    line-height: 18px;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `InfoCard_main__nJKgI`,
	"pillContainer": `InfoCard_pillContainer__vvH5O`,
	"gradeText": `InfoCard_gradeText__SBlA7`,
	"viewDetailsButton": `InfoCard_viewDetailsButton__ZaGCB`
};
export default ___CSS_LOADER_EXPORT___;
