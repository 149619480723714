import { useState } from 'react'
import classes from '../Login/Login.module.css'
import { useNavigate } from 'react-router-dom'
import InputField from '../../Components/UI/InputField/InputField'
import BackIcon from '../../icons/BackIcon'


const ForgotPin = () => {
    const [showReset, setShowReset] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")

    let navigate = useNavigate();


  const onSubmit = async () => {
    
   
  };


  const handleGoBack = () => {
    navigate(-1);
  };




   
    
    return(
        <div className={`d-flex flex-column align-items-center ${classes.main}`}>

            
            
            <div className={`d-flex flex-column ${classes.inputWrapper}`}>
            <img src='/thuto-login.svg' height={80}/>
              <div className='d-flex align-items-center gap-3 my-5 w-50' onClick={handleGoBack} role='button'>
                    <BackIcon /><div className={classes.titleText}>Forgot my pin</div>
                </div>
                <div className={`d-flex flex-column gap-5`} >
                    <InputField label="Your ID*" type="text" placeholder="Enter your ID"/>
                    <InputField label="Your email*" type="text" placeholder="Enter Your email*"/>
                    <InputField label="Phone number*" type="email" placeholder="Enter Phone Number"/>
                    <div className='d-flex align-items-center justify-content-between mt-4'><div className={classes.linkText} onClick={() => navigate('/register')} role='button'>Register new account</div><div className={classes.linkText} onClick={() => navigate('/login')} role='button'>I have an account</div></div>
                </div>
                <div className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`} onClick={onSubmit} role='button'>
                Continue
              </div>
            </div>

            
           
        </div>
    )
}

export default ForgotPin