import { useNavigate } from "react-router-dom"
import ResourceCardContainer from "../../Components/ResourceCardContainer/ResourceCardContainer"
import SectionContainer from "../../Components/SectionContainer/SectionContainer"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import InfoCard from "../../Components/UI/InfoCard/InfoCard"
import SearchBar from "../../Components/UI/SearchBar/SearchBar"
import ViewAllButton from "../../Components/UI/ViewAllButton/ViewAllButton"
import classes from "./Assessments.module.css"

const gradesReceived = [
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#F8C4CD',
        color: '#C36C7B',
        grade: '- -'
    },
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#F8C4CD',
        color: '#C36C7B',
        grade: '- -'
    }
]

const gradesReceived1 = [
    {
        subject: 'Mathematical Literacy Term 1',
        dateTime: 'Tue 12 Feb 2024',
        backgroundColor: '#F8C4CD',
        color: '#C36C7B',
        grade: '(A+) 97%'
    },
]

const statisticsData = [
    {
        title: 'Mathematics',
        buttonText: '2 assessments'
    },
    {
        title: 'Geography',
        buttonText: '6 not finished'
    },
    {
        title: 'English',
        buttonText: '2 not finished'
    },
    {
        title: 'English',
        buttonText: '2 not finished'
    }
]

const statisticsData1 = [
    {
        title: 'Biology',
        buttonText: '2 assessments'
    },
    {
        title: 'Adv. Mathematics',
        buttonText: '15 assessments'
    },
    {
        title: 'Physics',
        buttonText: '2 not finished'
    },
]

const Assessments = () => {
    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };
    return(
    <div className={classes.wrapper}>
        <div className={classes.innerWrapper}>
        <HeadingContainer heading="My assessments" sort="Show: All types" />
        <SearchBar className={`mb-5`} placeholder="Search in assessments"/>
        </div>
        
        <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3 mb-3`}>
            {statisticsData.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText}  color="#FFFFFF" backgroundColor="#FA6D6D" key={i} className={classes.infoCard}/>))}
        </div>
        <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3`}>
            {statisticsData1.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText} color="#FFFFFF" backgroundColor="#FA6D6D" key={i} className={classes.infoCard}/>))}
        </div>
        <SectionContainer heading="Assessments not finished (5)" sort="Arrange by: date" className={classes.innerWrapper}>
           {gradesReceived.map((item, i) => (<ResourceCardContainer key={i} subject={item.subject} dateTime={item.dateTime} pillbackgroundColor={item.backgroundColor} pillColor={item.color} grade={item.grade}/>))}
           <ViewAllButton title="View all" className="mt-4" onClick={() => navigate('assessment-subject')}/>
        </SectionContainer>

        <SectionContainer heading="Assessments taken (35)" sort="Arrange by: date" className={classes.innerWrapper}>
           {gradesReceived1.map((item, i) => (<ResourceCardContainer key={i} subject={item.subject} dateTime={item.dateTime} pillbackgroundColor={item.backgroundColor} pillColor={item.color} grade={item.grade}/>))}
           <ViewAllButton title="View all" className="mt-4" onClick={() => navigate('assessment-subject')}/>
        </SectionContainer>
    </div>
    )
}

export default Assessments