// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerformanceCardContainer_main__Dh37u{
    width: 200px;
    height: 135px;
    padding: 20px;
    flex-shrink: 0;
}

.PerformanceCardContainer_titleWrapper__8bKze {
    /* border-left: 3px solid #76A3CC; */
    margin-bottom: 23px;
}

.PerformanceCardContainer_titleWrapper__8bKze h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.PerformanceCardContainer_desc__I6J46 p{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/PerformanceCardContainer/PerformanceCardContainer.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb","sourcesContent":[".main{\n    width: 200px;\n    height: 135px;\n    padding: 20px;\n    flex-shrink: 0;\n}\n\n.titleWrapper {\n    /* border-left: 3px solid #76A3CC; */\n    margin-bottom: 23px;\n}\n\n.titleWrapper h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    margin: 0;\n}\n\n.desc p{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `PerformanceCardContainer_main__Dh37u`,
	"titleWrapper": `PerformanceCardContainer_titleWrapper__8bKze`,
	"desc": `PerformanceCardContainer_desc__I6J46`
};
export default ___CSS_LOADER_EXPORT___;
