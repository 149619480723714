const ChatIcon = ({size}) => {
    return(
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 16.9189C25 13.275 22.5447 10.0912 19.0823 9.13181C18.8646 4.0596 14.6708 0 9.5459 0C4.28219 0 0 4.28219 0 9.5459C0 11.2614 0.456619 12.9316 1.3237 14.398L0.0350952 19.0565L4.69379 17.7681C6.04172 18.5652 7.56226 19.0142 9.13162 19.0819C10.0908 22.5445 13.2748 25 16.9189 25C18.3735 25 19.788 24.6126 21.0308 23.8766L24.9647 24.9647L23.8766 21.0308C24.6126 19.788 25 18.3735 25 16.9189ZM4.92496 16.1842L2.13604 16.9558L2.90756 14.1668L2.7317 13.8918C1.90296 12.5952 1.46484 11.0924 1.46484 9.5459C1.46484 5.08995 5.08995 1.46484 9.5459 1.46484C14.0018 1.46484 17.627 5.08995 17.627 9.5459C17.627 14.0018 14.0018 17.627 9.5459 17.627C7.99942 17.627 6.49681 17.1888 5.2 16.3601L4.92496 16.1842ZM22.864 22.864L20.7945 22.2914L20.5181 22.4712C19.4468 23.1672 18.202 23.5352 16.9189 23.5352C14.0549 23.5352 11.5381 21.6833 10.6464 19.0279C15.0322 18.5223 18.5223 15.0322 19.0281 10.6462C21.6833 11.5381 23.5352 14.0549 23.5352 16.9189C23.5352 18.202 23.1672 19.4468 22.4712 20.5181L22.2914 20.7945L22.864 22.864Z" fill="#43B061"/>
        <path d="M8.81348 13.2324H10.2783V14.6973H8.81348V13.2324Z" fill="#43B061"/>
        <path d="M11.0107 7.32422C11.0107 7.74021 10.8414 8.12416 10.5339 8.40549L8.81348 9.9802V11.7676H10.2783V10.6253L11.5229 9.4862C12.1283 8.93211 12.4756 8.14419 12.4756 7.32422C12.4756 5.70869 11.1614 4.39453 9.5459 4.39453C7.93037 4.39453 6.61621 5.70869 6.61621 7.32422H8.08105C8.08105 6.51646 8.73814 5.85938 9.5459 5.85938C10.3537 5.85938 11.0107 6.51646 11.0107 7.32422Z" fill="#43B061"/>
        </svg>
    )
}

export default ChatIcon