const RequestIcon = ({active}) => {
    return(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.15234 1.17454C4.85664 5.7005 2.55776 10.2274 0.260248 14.7534C-0.0864075 15.4364 -0.0677693 16.1857 0.204001 16.8065C0.475771 17.4272 1.09862 18 1.91077 18H16.0957C16.9074 18 17.5295 17.425 17.7989 16.8047C18.0684 16.1845 18.0859 15.437 17.7357 14.7569L10.7328 1.16053C10.3732 0.462874 9.67496 -0.00301821 9.01194 1.47204e-05C8.14873 1.47204e-05 7.53743 0.445247 7.15234 1.17454ZM9.13329 1.98663L16.1343 15.5812C16.242 15.8112 16.3082 16.2 16.0956 16.2H1.91073C1.7586 16.2 1.75908 15.8273 1.86501 15.5689L8.74654 2.00948C8.83528 1.83664 9.06186 1.84955 9.13329 1.98663Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M8.99971 5.40025C8.76103 5.40025 8.53212 5.49508 8.36334 5.66386C8.19457 5.83264 8.09975 6.06154 8.09975 6.30023V10.8124C8.09975 11.0511 8.19457 11.28 8.36334 11.4488C8.53212 11.6176 8.76103 11.7124 8.99971 11.7124C9.23839 11.7124 9.4673 11.6176 9.63608 11.4488C9.80485 11.28 9.89967 11.0511 9.89967 10.8124V6.30023C9.89967 6.06154 9.80485 5.83264 9.63608 5.66386C9.4673 5.49508 9.23839 5.40025 8.99971 5.40025Z" fill={active ? "#43B061" : "#262626"}/>
        <path d="M9.89939 13.5122C9.89939 13.7509 9.80457 13.9798 9.6358 14.1486C9.46702 14.3173 9.23812 14.4122 8.99943 14.4122C8.76075 14.4122 8.53184 14.3173 8.36306 14.1486C8.19429 13.9798 8.09947 13.7509 8.09947 13.5122C8.09947 13.2735 8.19429 13.0446 8.36306 12.8758C8.53184 12.707 8.76075 12.6122 8.99943 12.6122C9.23812 12.6122 9.46702 12.707 9.6358 12.8758C9.80457 13.0446 9.89939 13.2735 9.89939 13.5122Z" fill={active ? "#43B061" : "#262626"}/>
        </svg>
    )
}

export default RequestIcon