// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_inputWrapper__rln25{
    border: 1px solid #CACACA;
    width: 100%;
    height: 51px;
    padding: 0 15px;
    background: #FFFFFF;
    border-radius: 100px;
    position: relative;
}

.SearchBar_inputWrapper__rln25 input{
   border: none;
   width: 100%;
}

.SearchBar_inputWrapper__rln25 input:focus{
    outline: none;
}

.SearchBar_inputWrapper__rln25 input::placeholder{
    color: #CACACA;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;GACG,YAAY;GACZ,WAAW;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".inputWrapper{\n    border: 1px solid #CACACA;\n    width: 100%;\n    height: 51px;\n    padding: 0 15px;\n    background: #FFFFFF;\n    border-radius: 100px;\n    position: relative;\n}\n\n.inputWrapper input{\n   border: none;\n   width: 100%;\n}\n\n.inputWrapper input:focus{\n    outline: none;\n}\n\n.inputWrapper input::placeholder{\n    color: #CACACA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `SearchBar_inputWrapper__rln25`
};
export default ___CSS_LOADER_EXPORT___;
