// src/services/auth.js
import { authentication, readMe, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';
import { setToken } from '../utilities/functions';

const authClient = directus.with(authentication('json')).with(rest())
const client = directus.with(rest());

export const login = async (email, password) => {
  try {
    const result = await authClient.login(email, password);

    setToken(result.access_token, result.expires)

    console.log("QUERYYYY", result)
   
    return await client.request(readMe({access_token: result.access_token}));
  } catch (error) {
    throw new Error('Login failed: ' + error.message);
  }
};

export const logout = async () => {
  try {
    await authClient.logout();
  } catch (error) {
    throw new Error('Logout failed: ' + error.message);
  }
};

export const getCurrentUser = async () => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    return await client.request(readMe({access_token: token}));
  } catch (error) {
    throw new Error('Failed to fetch current user: ' + error.message);
  }
};