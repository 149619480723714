import CardContainer from '../UI/CardContainer/CardContainer'
import IconCard from '../UI/IconCard/IconCard'
import classes from './ActivitiesCardContainer.module.css'

const ActivitiesCardContainer = ({icon, title, description, backgroundColor}) => {
    return(
        <CardContainer className={classes.main} style={{backgroundColor: backgroundColor}}>
           <div className={`d-flex align-items-center gap-3 ${classes.activityTitleWrapper}`}>
            <IconCard>
                {icon}
            </IconCard>
            <h3>{title}</h3>
           </div>
           <div className={classes.desc}>
            <p>{description}</p>
           </div>
        </CardContainer>
    )
}

export default ActivitiesCardContainer