import CardContainer from '../UI/CardContainer/CardContainer'
import classes from './ResourceCardContainer.module.css'

const ResourceCardContainer = ({subject, dateTime, pillbackgroundColor, pillColor, grade}) => {
    return(
        <CardContainer className={`d-flex align-items-center justify-content-between ${classes.main}`}>
            <div>
                <h3>{subject}</h3>
                <div className={`d-flex align-items-center gap-2 mt-2`}>
                    <div className={`${classes.pillContainer}`} style={{backgroundColor: pillbackgroundColor, color: pillColor}}>NEW</div>
                    <h5>{dateTime}</h5>
                </div>
            </div>
            <div className={classes.gradeText}>
            {grade}
            </div>
        </CardContainer>
    )
}

export default ResourceCardContainer