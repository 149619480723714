import classes from './InputField.module.css'

const InputField = ({label, type, placeholder, labelBackground, onChange, value, children}) => {
    return(
        <div className={`${classes.inputWrapper}`}>
            <label style={{background: labelBackground}}>{label}</label>
            {!children && <input type={type} placeholder={placeholder} onChange={onChange} value={value}/>}
            {children}
        </div>
    )
}

export default InputField