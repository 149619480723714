const BookPlayIcon = ({}) => {
    return(
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0.5H13.875C13.1367 0.5 12.4704 0.808746 12 1.30186C11.5296 0.808746 10.8633 0.5 10.125 0.5H0V17.2073H5.38936C6.49805 19.7306 9.04369 21.5 12 21.5C14.9563 21.5 17.502 19.7306 18.6106 17.2073H24V0.5ZM22.5938 15.8176H19.0558C19.1524 15.3537 19.2031 14.8734 19.2031 14.3816C19.2031 13.824 19.1378 13.2813 19.0145 12.7603H20.6484V11.3706H18.5259C18.2555 10.8005 17.9109 10.2715 17.5042 9.79559H20.6484V8.40589H15.9097C14.9686 7.80243 13.8767 7.41012 12.7031 7.29723V3.04779C12.7031 2.40922 13.2288 1.8897 13.875 1.8897H22.5938V15.8176ZM12 20.1103C8.80359 20.1103 6.20311 17.5404 6.20311 14.3816C6.20311 11.2228 8.80359 8.65293 12 8.65293C15.1964 8.65293 17.7969 11.2228 17.7969 14.3816C17.7969 17.5404 15.1964 20.1103 12 20.1103ZM1.40625 1.8897H10.125C10.7712 1.8897 11.2969 2.40922 11.2969 3.04779V7.29718C10.1233 7.41007 9.03141 7.80238 8.0902 8.40584H3.35156V9.79554H6.4957C6.08911 10.2714 5.74444 10.8004 5.47402 11.3705H3.35156V12.7602H4.98553C4.86225 13.2812 4.79686 13.824 4.79686 14.3816C4.79686 14.8733 4.84762 15.3536 4.94419 15.8176H1.40625V1.8897Z" fill="#FA6D6D"/>
        <path d="M3.35156 5.44118H9.35156V6.83089H3.35156V5.44118Z" fill="#FA6D6D"/>
        <path d="M14.6484 5.44118H20.6484V6.83089H14.6484V5.44118Z" fill="#FA6D6D"/>
        <path d="M15.3516 14.9834V13.7799L10.4813 11.0011L9.42656 11.6028V17.1604L10.4813 17.7621L15.3516 14.9834ZM10.8328 12.8063L13.5938 14.3816L10.8328 15.9569V12.8063Z" fill="#FA6D6D"/>
        </svg>
    )
}

export default BookPlayIcon