// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionContainer_main__SLZ-T{
 margin-top: 50px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/SectionContainer/SectionContainer.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB","sourcesContent":[".main{\n margin-top: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `SectionContainer_main__SLZ-T`
};
export default ___CSS_LOADER_EXPORT___;
