// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Points_wrapper__d9E9h{
 padding: 0 30px 40px 30px;
}

.Points_wrapper__d9E9h h2{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.Points_wrapper__d9E9h h1{
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    margin: 0;
}


.Points_cardContainer__McRmN{
    padding: 40px;
    border-radius: 20px;
}

.Points_timetableButton__A9UsZ{
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.Points_pointsCard__JHcqt{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 20px;
    padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Points/Points.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".wrapper{\n padding: 0 30px 40px 30px;\n}\n\n.wrapper h2{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n}\n\n.wrapper h1{\n    font-size: 20px;\n    font-weight: 275;\n    line-height: 30px;\n    text-align: center;\n    margin: 0;\n}\n\n\n.cardContainer{\n    padding: 40px;\n    border-radius: 20px;\n}\n\n.timetableButton{\n    width: fit-content;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: #43B061;\n}\n\n.pointsCard{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    margin-bottom: 20px;\n    padding: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Points_wrapper__d9E9h`,
	"cardContainer": `Points_cardContainer__McRmN`,
	"timetableButton": `Points_timetableButton__A9UsZ`,
	"pointsCard": `Points_pointsCard__JHcqt`
};
export default ___CSS_LOADER_EXPORT___;
