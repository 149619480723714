// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_main__2mhdy{
    font-family: "Poppins", sans-serif;
    background-color: #F9F9F9;
}

.Layout_header__PQb99{
    width: 100%;
    padding: 30px 30px 0px 30px;
}

.Layout_userImage__KfHa5{
    background: grey;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.Layout_logoWrapper__HR7tB img{
    height: 46px;
}

.Layout_content__BJTy7{
    margin: 40px 0;
    /* padding: 0 30px; */
    height: calc(100vh - 106px);
    overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,sBAAsB;IACtB,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".main{\n    font-family: \"Poppins\", sans-serif;\n    background-color: #F9F9F9;\n}\n\n.header{\n    width: 100%;\n    padding: 30px 30px 0px 30px;\n}\n\n.userImage{\n    background: grey;\n    width: 45px;\n    height: 45px;\n    border-radius: 10px;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center bottom;\n}\n\n.logoWrapper img{\n    height: 46px;\n}\n\n.content{\n    margin: 40px 0;\n    /* padding: 0 30px; */\n    height: calc(100vh - 106px);\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Layout_main__2mhdy`,
	"header": `Layout_header__PQb99`,
	"userImage": `Layout_userImage__KfHa5`,
	"logoWrapper": `Layout_logoWrapper__HR7tB`,
	"content": `Layout_content__BJTy7`
};
export default ___CSS_LOADER_EXPORT___;
