import Cookies from 'js-cookie';

// Function to set token
export const setToken = (token, expiresIn) => {
  Cookies.set('authToken', token, { expires: expiresIn / (24 * 60 * 60) }); // expiresIn should be in seconds
};

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const dayNames = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

export const getDateTime = () => {
    const currentDate = new Date();

  const year = currentDate.getFullYear();
  const monthIndex = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const dayIndex = currentDate.getDay();
  const date = currentDate.getDate();
  let hours = currentDate.getHours();
  const minutes =  currentDate.getHours()
  const period = hours >= 12 ? 'PM' : 'AM';
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const dateWithSuffix = getDayWithSuffix(date);

  return {year, month: monthNames[monthIndex], day: dayNames[dayIndex], date: dateWithSuffix, hours, minutes, period}
}

function splitTimeString(timeString) {
    if (!timeString) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  
    return { hours, minutes, seconds };
}

function getDayWithSuffix(day) {
  if (day > 3 && day < 21) return `${day}th`; // Covers 4th to 20th
  switch (day % 10) {
    case 1: return `${day}st`;
    case 2: return `${day}nd`;
    case 3: return `${day}rd`;
    default: return `${day}th`;
  }
}

