// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeadingContainer_wrapper__Xzv6j h2{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
}

.HeadingContainer_showTypes__KzGkb{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/HeadingContainer/HeadingContainer.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".wrapper h2{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n    margin: 0;\n}\n\n.showTypes{\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HeadingContainer_wrapper__Xzv6j`,
	"showTypes": `HeadingContainer_showTypes__KzGkb`
};
export default ___CSS_LOADER_EXPORT___;
