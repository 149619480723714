import classes from './HorizontalScrollContainer.module.css'

const HorizontalScrollContainer = ({children, heading, onClick}) => {
    return(
        <div className={classes.wrapper}>
            <div className={`d-flex align-items-center justify-content-between ${classes.headingWrapper}`}><h3>{heading}</h3><button onClick={onClick}>View more</button></div>
            <div className={`${classes.contentContainer} d-flex align-items-center gap-4`}>
                {children}
            </div>
        </div>    
    )
}

export default HorizontalScrollContainer