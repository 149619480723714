// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Performance_wrapper__2BrYn {
    padding-bottom: 30px;
}
.Performance_innerWrapper__NLG22{
    padding: 0 30px;
}

.Performance_wrapper__2BrYn h2{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.Performance_wrapper__2BrYn h1{
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    margin: 0;
}


.Performance_cardContainer__s5idd{
    padding: 15px;
    border-radius: 20px;
}

.Performance_timetableButton__--3yB{
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.Performance_statisticsContainer__HdM5z{
    width: 100vw;
    overflow-x: auto;
    padding: 0 30px;
}

.Performance_receivedWrapper__Vdrqg{
    margin-top: 70px;
}


.Performance_receivedWrapper__Vdrqg, .Performance_receivedWrapper__Vdrqg h6{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.Performance_receivedCard__bi0pg{
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Performance/Performance.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".wrapper {\n    padding-bottom: 30px;\n}\n.innerWrapper{\n    padding: 0 30px;\n}\n\n.wrapper h2{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n}\n\n.wrapper h1{\n    font-size: 20px;\n    font-weight: 275;\n    line-height: 30px;\n    text-align: center;\n    margin: 0;\n}\n\n\n.cardContainer{\n    padding: 15px;\n    border-radius: 20px;\n}\n\n.timetableButton{\n    width: fit-content;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: #43B061;\n}\n\n.statisticsContainer{\n    width: 100vw;\n    overflow-x: auto;\n    padding: 0 30px;\n}\n\n.receivedWrapper{\n    margin-top: 70px;\n}\n\n\n.receivedWrapper, .receivedWrapper h6{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n}\n\n.receivedCard{\n    padding: 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Performance_wrapper__2BrYn`,
	"innerWrapper": `Performance_innerWrapper__NLG22`,
	"cardContainer": `Performance_cardContainer__s5idd`,
	"timetableButton": `Performance_timetableButton__--3yB`,
	"statisticsContainer": `Performance_statisticsContainer__HdM5z`,
	"receivedWrapper": `Performance_receivedWrapper__Vdrqg`,
	"receivedCard": `Performance_receivedCard__bi0pg`
};
export default ___CSS_LOADER_EXPORT___;
