// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Sidebar.css */
  
  .AlertDialog_dialogBox__GnhG1 {
    position: fixed;
    width: 507px;
    height: -moz-fit-content;
    height: fit-content;
    top: -333px;
    left: calc(50vw - 253.5px);
    padding: 20px;
    border-radius: 10px;
    background-color: #F9F9F9;
    transition: top 0.3s ease;
    z-index: 1000;
    box-shadow: 0px 4px 50px 0px #00000066;
  }

  .AlertDialog_dialogBox__GnhG1.AlertDialog_open__5BSE1 {
    top: 133px; /* Slide in */
  }

  .AlertDialog_titleText__\\+RnZQ{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .AlertDialog_confirmButton__RPu8o, .AlertDialog_cancelButton__BHyq5{
    width: 100%;
    height: 54px;
    padding: 15px 0px;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }

  .AlertDialog_cancelButton__BHyq5{
    background-color: #FA6D6D;
  }
  
  
  .AlertDialog_overlay__9B3\\+J {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.6);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px); /* Apply blur effect */
    z-index: 999; /* Behind the sidebar */
  }







`, "",{"version":3,"sources":["webpack://./src/Components/UI/AlertDialog/AlertDialog.module.css"],"names":[],"mappings":"AAAA,oBAAoB;;EAElB;IACE,eAAe;IACf,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,WAAW;IACX,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;IACb,sCAAsC;EACxC;;EAEA;IACE,UAAU,EAAE,aAAa;EAC3B;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,kCAA0B;YAA1B,0BAA0B,EAAE,sBAAsB;IAClD,YAAY,EAAE,uBAAuB;EACvC","sourcesContent":["/* src/Sidebar.css */\n  \n  .dialogBox {\n    position: fixed;\n    width: 507px;\n    height: fit-content;\n    top: -333px;\n    left: calc(50vw - 253.5px);\n    padding: 20px;\n    border-radius: 10px;\n    background-color: #F9F9F9;\n    transition: top 0.3s ease;\n    z-index: 1000;\n    box-shadow: 0px 4px 50px 0px #00000066;\n  }\n\n  .dialogBox.open {\n    top: 133px; /* Slide in */\n  }\n\n  .titleText{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n  }\n\n  .confirmButton, .cancelButton{\n    width: 100%;\n    height: 54px;\n    padding: 15px 0px;\n    border-radius: 10px;\n    background-color: var(--primary-color);\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: center;\n    color: #FFFFFF;\n  }\n\n  .cancelButton{\n    background-color: #FA6D6D;\n  }\n  \n  \n  .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(38, 38, 38, 0.6);\n    backdrop-filter: blur(2px); /* Apply blur effect */\n    z-index: 999; /* Behind the sidebar */\n  }\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBox": `AlertDialog_dialogBox__GnhG1`,
	"open": `AlertDialog_open__5BSE1`,
	"titleText": `AlertDialog_titleText__+RnZQ`,
	"confirmButton": `AlertDialog_confirmButton__RPu8o`,
	"cancelButton": `AlertDialog_cancelButton__BHyq5`,
	"overlay": `AlertDialog_overlay__9B3+J`
};
export default ___CSS_LOADER_EXPORT___;
