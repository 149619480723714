import { useState } from 'react'
import classes from './ChangePIN.module.css'
import HeadingContainer from '../../../Components/UI/HeadingContainer/HeadingContainer'
import { useNavigate } from 'react-router-dom'
import ConfirmButton from '../../../Components/UI/ConfirmButton/ConfirmButton'
import InputField from '../../../Components/UI/InputField/InputField'

const ChangePIN = () => {
    const [activeTab, setActiveTab] = useState(0)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.main}>
             <HeadingContainer heading="My account / Change PIN" onClick={handleGoBack}/>
             <div className={`d-flex flex-column mt-5 gap-5`} >
                <InputField label="Email*" type="text" labelBackground="#F9F9F9"/>
                <InputField label="PIN*" type="number" labelBackground="#F9F9F9"/>
                <InputField label="New PIN*" type="number" labelBackground="#F9F9F9"/>
                <InputField label="Confirm PIN*" type="number" labelBackground="#F9F9F9"/>
                <ConfirmButton buttonText="Save changes"/>
            </div>
        </div>
    )
}

export default ChangePIN