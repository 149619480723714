import { useState } from 'react'
import classes from './ReourceCategory.module.css'
import SearchBar from '../../../Components/UI/SearchBar/SearchBar'
import HeadingContainer from '../../../Components/UI/HeadingContainer/HeadingContainer'
import { useNavigate } from 'react-router-dom'
import SectionContainer from '../../../Components/SectionContainer/SectionContainer'


const attendanceTableHeader = [
    {
        title: "Reource name",
        width: 'auto'
    },
    {
        title: "Date",
        width: '100px'
    }
]

const attendanceTableContent = [
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    },
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    },
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    },
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    },
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    },
    {
        name: "Mathematical Literacy Term 3",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
    }
]

const ReourceCategory = () => {
    const [activeTab, setActiveTab] = useState(0)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.main}>
            <HeadingContainer heading="Resource library / Category" sort="Show: All types" onClick={handleGoBack}/>
            <SearchBar placeholder="Search in category" className="mt-4"/>
            
            <SectionContainer heading="Resources in this category (35)" sort="Arrange by: date" >
            
            <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                <tr>
                {attendanceTableHeader.map((item, i) => (
                    <th style={{width: item.width}} key={i}>{item.title}</th>
                ))}
                </tr>
                {attendanceTableContent.map((item, i) => (<tr>
                    <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                    <td>{item.date}</td>
                </tr>))}
                </table>      
            </SectionContainer>
        </div>
    )
}

export default ReourceCategory