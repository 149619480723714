import SearchIcon from '../../../icons/SearchIcon'
import classes from './SearchBar.module.css'

const SearchBar = ({className, placeholder}) => {
    return(
        <div className={`${classes.inputWrapper} ${className} d-flex align-items-center`}>
            <input type="text" placeholder={placeholder} /><SearchIcon />
        </div>
    )
}

export default SearchBar