import classes from './CardContainer.module.css'

const CardContainer = ({children, className, style, role, onClick}) => {
    return(
        <div className={`${className} ${classes.wrapper}`} style={style} role={role} onClick={onClick}>
            {children}
        </div>
    )
}

export default CardContainer