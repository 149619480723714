// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewAllButton_viewButton__B8saQ{
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/ViewAllButton/ViewAllButton.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,2BAA2B;AAC/B","sourcesContent":[".viewButton{\n    width: fit-content;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewButton": `ViewAllButton_viewButton__B8saQ`
};
export default ___CSS_LOADER_EXPORT___;
