// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalScrollContainer_wrapper__8v\\+T5{
    width: 100vw;
}

.HorizontalScrollContainer_wrapper__8v\\+T5 button{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: none;
    background: none;
}

.HorizontalScrollContainer_wrapper__8v\\+T5 h3{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.HorizontalScrollContainer_headingWrapper__xuI8a, .HorizontalScrollContainer_contentContainer__DzYtp{
    padding: 0 30px;
}

.HorizontalScrollContainer_contentContainer__DzYtp{
    min-height: 150px;
    width: 100vw;
    gap: 30px;
    overflow-x: auto;
    margin: 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/HorizontalScrollContainer/HorizontalScrollContainer.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".wrapper{\n    width: 100vw;\n}\n\n.wrapper button{\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 18px;\n    border: none;\n    background: none;\n}\n\n.wrapper h3{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n}\n\n.headingWrapper, .contentContainer{\n    padding: 0 30px;\n}\n\n.contentContainer{\n    min-height: 150px;\n    width: 100vw;\n    gap: 30px;\n    overflow-x: auto;\n    margin: 30px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HorizontalScrollContainer_wrapper__8v+T5`,
	"headingWrapper": `HorizontalScrollContainer_headingWrapper__xuI8a`,
	"contentContainer": `HorizontalScrollContainer_contentContainer__DzYtp`
};
export default ___CSS_LOADER_EXPORT___;
