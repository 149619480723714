const LessonsIcon = ({active}) => {
    return(
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.60879 0C1.90141 0 0.5 1.39088 0.5 3.08537V20.9146C0.5 22.6091 1.90141 24 3.60874 24H16.3913C18.0986 24 19.5 22.6091 19.5 20.9146V6.00001C19.5 5.22418 19.1891 4.47938 18.6363 3.9308L15.5394 0.857158C14.9867 0.308579 14.2363 0 13.4546 0H3.60879ZM3.60879 1.71427H12.591V4.2857C12.591 5.69546 13.7615 6.85712 15.1819 6.85712H17.7728V20.9146C17.7728 21.6894 17.1719 22.2857 16.3913 22.2857H3.60879C2.82817 22.2857 2.2273 21.6894 2.2273 20.9146V3.08537C2.2273 2.31061 2.82817 1.71427 3.60879 1.71427ZM14.3182 2.06921L17.4152 5.14285H15.1818C14.6884 5.14285 14.3182 4.7754 14.3182 4.2857L14.3182 2.06921Z" fill="#262626"/>
        <path d="M5.68181 9.4286C5.20484 9.4286 4.81816 9.81236 4.81816 10.2858C4.81816 10.7591 5.20484 11.1429 5.68181 11.1429H14.3182C14.7952 11.1429 15.1818 10.7591 15.1818 10.2858C15.1818 9.81236 14.7952 9.4286 14.3182 9.4286H5.68181Z" fill="#262626"/>
        <path d="M5.68181 13.7143C5.20484 13.7143 4.81816 14.0981 4.81816 14.5714C4.81816 15.0448 5.20484 15.4286 5.68181 15.4286H14.3182C14.7952 15.4286 15.1818 15.0448 15.1818 14.5714C15.1818 14.0981 14.7952 13.7143 14.3182 13.7143H5.68181Z" fill="#262626"/>
        <path d="M5.68181 18C5.20484 18 4.81816 18.3838 4.81816 18.8572C4.81816 19.3306 5.20484 19.7143 5.68181 19.7143H14.3182C14.7952 19.7143 15.1818 19.3306 15.1818 18.8572C15.1818 18.3838 14.7952 18 14.3182 18H5.68181Z" fill="#262626"/>
        </svg>
    )
}

export default LessonsIcon