// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DayViewCustomEvent_container__kygeQ{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #0000000D;
    margin-top: 30px;
}

.DayViewCustomEvent_iconWrapper__81Exa{
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px #00000026;
    flex-shrink: 0;
}

.DayViewCustomEvent_titleText__qvzrA{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.DayViewCustomEvent_subTitleText__ccSmH{ 
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}



`, "",{"version":3,"sources":["webpack://./src/Components/CalendarComponent/Components/CustomEvent/DayViewCustomEvent.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".container{\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    background: #FFFFFF;\n    box-shadow: 0px 2px 2px 0px #0000000D;\n    margin-top: 30px;\n}\n\n.iconWrapper{\n    width: 40px;\n    height: 40px;\n    border-radius: 10px;\n    background: #FFFFFF;\n    box-shadow: 0px 2px 5px 0px #00000026;\n    flex-shrink: 0;\n}\n\n.titleText{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.subTitleText{ \n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DayViewCustomEvent_container__kygeQ`,
	"iconWrapper": `DayViewCustomEvent_iconWrapper__81Exa`,
	"titleText": `DayViewCustomEvent_titleText__qvzrA`,
	"subTitleText": `DayViewCustomEvent_subTitleText__ccSmH`
};
export default ___CSS_LOADER_EXPORT___;
