import { useState } from 'react'
import classes from './AllGrades.module.css'
import SearchBar from '../../../Components/UI/SearchBar/SearchBar'
import CardContainer from '../../../Components/UI/CardContainer/CardContainer'
import HeadingContainer from '../../../Components/UI/HeadingContainer/HeadingContainer'
import { useNavigate } from 'react-router-dom'


const attendanceTableHeader = [
    {
        title: "Lesson name",
        width: 'auto'
    },
    {
        title: "Date",
        width: 'auto'
    },
    {
        title: "Category",
        width: 'auto'
    },
    {
        title: "Grade",
        width: '100px'
    },
    {
        title: "%",
        width: '100px'
    }
]

const attendanceTableContent = [
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    },
    {
        name: "English Grammar Term 2",
        date: <div>Tuesday,<br /> 16th Feb, 2024</div>,
        category: "Mathematics",
        grade: "A+",
        percentage: "90%"
    }
]

const AllGrades = () => {
    const [activeTab, setActiveTab] = useState(0)

    let navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    return(
        <div className={classes.main}>
             <HeadingContainer heading="My performance / All grades" sort="Show: All types" onClick={handleGoBack}/>
            <SearchBar placeholder="Search learners" className="mt-4"/>
            <CardContainer className={classes.summaryCard}>
            Your average grade this year is B+ 
            </CardContainer>
            
            <div className='mt-4'>
            
            <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                        <tr>
                        {attendanceTableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {attendanceTableContent.map((item, i) => (<tr>
                            <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                            <td>{item.date}</td>
                            <td>{item.category}</td>
                            <td>{item.grade}</td>
                            <td>{item.percentage}</td>
                        </tr>))}
                        </table>      
            </div>
        </div>
    )
}

export default AllGrades