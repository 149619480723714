// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.IconContainer_iconContainer__-vUzV{
    border: 1px solid var(--primary-color);
    width: 45px;
    height: 45px;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/IconContainer/IconContainer.module.css"],"names":[],"mappings":";AACA;IACI,sCAAsC;IACtC,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["\n.iconContainer{\n    border: 1px solid var(--primary-color);\n    width: 45px;\n    height: 45px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `IconContainer_iconContainer__-vUzV`
};
export default ___CSS_LOADER_EXPORT___;
