import { useState } from "react";
import CalendarComponent from "../../Components/CalendarComponent/CalendarComponent"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import classes from "./Timetable.module.css"
import LessonDetails from "../../Components/LessonDetails/LessonDetails";
import SectionTitleContainer from "../../Components/UI/SectionTitleContainer/SectionTitleContainer";
import CardContainer from "../../Components/UI/CardContainer/CardContainer";
import SolidStarIcon from "../../icons/SolidStarIcon";


const AboutLessonCard = ({title, value}) => (<div className="col-4">
<div className={classes.aboutLessonCard}>
    <p>{title}</p>
    <h5>{value}</h5>
</div>
</div>)

const resourcesData = [
    {
        title: "English Grammar Term 2"
    },
    {
        title: "English Grammar Term 3"
    }
]

const Timetable = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleLessonDetails = () => {
        setIsOpen(!isOpen);
    }

    return(
        <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
            <HeadingContainer heading="My timetable" sort="Show: All types"/>
            </div>
            <CalendarComponent className={`${classes.innerWrapper} mt-4`} toggleDetails={toggleLessonDetails}/>
            <LessonDetails isOpen={isOpen} toggleDetails={toggleLessonDetails}>
                <div className={classes.lessonDetailsWrapper}>
                <SectionTitleContainer heading="About this lesson" className="mt-4"/>
                <div className="row">
                    <AboutLessonCard title="Type" value="Interactive"/>
                    <AboutLessonCard title="Duration" value=" 1h: 30 min"/>
                    <AboutLessonCard title="Attended:" value="No"/>
                </div>
            
                <SectionTitleContainer heading="Lesson scheduled" className="mt-4"/>
                <div className={`d-flex align-items-center justify-content-between ${classes.aboutLessonCard}`} style={{height: "fit-content"}}>
                    <p>9AM - 10AM / Tuesday, 16th Feb, 2024</p>
                    <div className={classes.viewButton} role="button">View calendar</div>
                </div>

                <SectionTitleContainer heading="Resources for this lesson" className="mt-4" sort="Show: All types"/>
                    {resourcesData.map((item, i) => (<CardContainer className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}>
                            <div>
                                <div className={`mb-2 ${classes.resourcePill}`}>Resource</div>
                                <h4>{item.title}</h4>
                            </div>
                            <SolidStarIcon />
                    </CardContainer>))}

                <SectionTitleContainer heading="Activity for this lesson" className="mt-4" sort="Show: All types"/>
                   <CardContainer className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}>
                        <h4 style={{color: "#959595"}}>You have no activity for this lesson</h4>
                    </CardContainer>
                </div>
            </LessonDetails>

        </div>
    )
}

export default Timetable