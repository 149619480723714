// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LessonCardContainer_main__WNq1C{
    padding: 20px;
    margin-bottom: 20px;
}

.LessonCardContainer_main__WNq1C h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.LessonCardContainer_main__WNq1C h5{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.LessonCardContainer_pillContainer__ACug9{
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    gap: 10px;
    border-radius: 100px;
    background: #E3F0E7;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/LessonCardContainer/LessonCardContainer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;IACT,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".main{\n    padding: 20px;\n    margin-bottom: 20px;\n}\n\n.main h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    margin: 0;\n}\n\n.main h5{\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    margin: 0;\n}\n\n.pillContainer{\n    width: fit-content;\n    padding: 3px 10px;\n    gap: 10px;\n    border-radius: 100px;\n    background: #E3F0E7;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `LessonCardContainer_main__WNq1C`,
	"pillContainer": `LessonCardContainer_pillContainer__ACug9`
};
export default ___CSS_LOADER_EXPORT___;
