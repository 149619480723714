// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Assessments_wrapper__cLwgu {
    padding-bottom: 30px;
}
.Assessments_innerWrapper__eihdc{
    padding: 0 30px;
}



.Assessments_wrapper__cLwgu h1{
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    margin: 0;
}


.Assessments_cardContainer__jgcXH{
    padding: 15px;
    border-radius: 20px;
}

.Assessments_timetableButton__CYOaS{
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.Assessments_statisticsContainer__gKi5v{
    width: 100vw;
    overflow-x: auto;
    padding: 0 30px;
}

.Assessments_infoCard__1aAgf{
    width: 222px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Assessments/Assessments.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB;;;;AAIA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".wrapper {\n    padding-bottom: 30px;\n}\n.innerWrapper{\n    padding: 0 30px;\n}\n\n\n\n.wrapper h1{\n    font-size: 20px;\n    font-weight: 275;\n    line-height: 30px;\n    text-align: center;\n    margin: 0;\n}\n\n\n.cardContainer{\n    padding: 15px;\n    border-radius: 20px;\n}\n\n.timetableButton{\n    width: fit-content;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: #43B061;\n}\n\n.statisticsContainer{\n    width: 100vw;\n    overflow-x: auto;\n    padding: 0 30px;\n}\n\n.infoCard{\n    width: 222px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Assessments_wrapper__cLwgu`,
	"innerWrapper": `Assessments_innerWrapper__eihdc`,
	"cardContainer": `Assessments_cardContainer__jgcXH`,
	"timetableButton": `Assessments_timetableButton__CYOaS`,
	"statisticsContainer": `Assessments_statisticsContainer__gKi5v`,
	"infoCard": `Assessments_infoCard__1aAgf`
};
export default ___CSS_LOADER_EXPORT___;
