// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeInformation_main__aeqh3{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 30px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Account/ChangeInformation/ChangeInformation.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".main{\n    height: calc(100vh - 80px);\n    overflow-y: auto;\n    padding: 40px 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ChangeInformation_main__aeqh3`
};
export default ___CSS_LOADER_EXPORT___;
