// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReourceCategory_main__zq8R8{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 30px;
}

.ReourceCategory_learnerTable__fBHVX tr th, .ReourceCategory_learnerTable__fBHVX tr td{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #262626;
    text-align: left;
    padding: 10px 0;
}

.ReourceCategory_learnerTable__fBHVX tr {
    border-bottom: 1px solid #E8E8E8;
}

.ReourceCategory_learnerTable__fBHVX .ReourceCategory_removeButton__AnMnk{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
    text-transform: uppercase;
}

.ReourceCategory_notificationDot__RB1iH{
    width: 10px;
    height: 10px;   
    border-radius: 100px;
    background: var(--secondary-color);
    margin-right: 15px;
}

.ReourceCategory_summaryCard__NI3KB{
    padding: 30px 40px;;
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    margin: 40px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Resources/ResourceCategory/ReourceCategory.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".main{\n    height: calc(100vh - 80px);\n    overflow-y: auto;\n    padding: 40px 30px;\n}\n\n.learnerTable tr th, .learnerTable tr td{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    color: #262626;\n    text-align: left;\n    padding: 10px 0;\n}\n\n.learnerTable tr {\n    border-bottom: 1px solid #E8E8E8;\n}\n\n.learnerTable .removeButton{\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    text-decoration: underline;\n    color: #43B061;\n    text-transform: uppercase;\n}\n\n.notificationDot{\n    width: 10px;\n    height: 10px;   \n    border-radius: 100px;\n    background: var(--secondary-color);\n    margin-right: 15px;\n}\n\n.summaryCard{\n    padding: 30px 40px;;\n    font-size: 20px;\n    font-weight: 275;\n    line-height: 30px;\n    text-align: center;\n    margin: 40px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ReourceCategory_main__zq8R8`,
	"learnerTable": `ReourceCategory_learnerTable__fBHVX`,
	"removeButton": `ReourceCategory_removeButton__AnMnk`,
	"notificationDot": `ReourceCategory_notificationDot__RB1iH`,
	"summaryCard": `ReourceCategory_summaryCard__NI3KB`
};
export default ___CSS_LOADER_EXPORT___;
