import { useState } from 'react'
import classes from '../Login/Login.module.css'
import { useNavigate } from 'react-router-dom'
import InputField from '../../Components/UI/InputField/InputField'
import BackIcon from '../../icons/BackIcon'


const Register = () => {
    const [showReset, setShowReset] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")

    let navigate = useNavigate();


  const onSubmit = async () => {
    
   
  };

  const handleGoBack = () => {
    navigate(-1);
  };




   
    
    return(
        <div className={`d-flex flex-column align-items-center ${classes.main}`}>

            
            
            <div className={`d-flex flex-column ${classes.inputWrapper}`}>
            <img src='/thuto-login.svg' height={80}/>
              <div className='d-flex align-items-center gap-3 my-5 w-50' onClick={handleGoBack} role='button'>
                    <BackIcon /><div className={classes.titleText}>Register new account</div>
                </div>
                <div className={`d-flex flex-column gap-5`} >
                    <InputField label="First name*" type="text" placeholder="Enter First Name"/>
                    <InputField label="Last name*" type="text" placeholder="Enter Last Name"/>
                    <InputField label="Email*" type="email" placeholder="Enter Email"/>
                    <InputField label="Phone*" type="number" placeholder="Enter Phone Number"/>
                    <InputField label="School name" type="text" placeholder="Enter School Name"/>
                    <InputField label="District" type="text" placeholder="Enter District"/>
                    <InputField label="Circuit" type="text" placeholder="Enter Circuit"/>
                    <InputField label="Grade" type="text" placeholder=""/>
                    <InputField label="Enter a login PIN" type="text" placeholder="Enter PIN"/>
                    <InputField label="Confirm your PIN" type="text" placeholder="Enter PIN"/>
                </div>
                <div className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`} onClick={onSubmit} role='button'>
                Continue
              </div>
            </div>

            
           
        </div>
    )
}

export default Register