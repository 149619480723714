// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomToolbar_main__2tkz\\+{

}

.CustomToolbar_navButton__nHvxQ{
    width:40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    flex-shrink: 0;   
}

.CustomToolbar_dateText__z8JGO{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.CustomToolbar_classesText__sYKwP{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.CustomToolbar_scheduleButton__yps4V{
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
}

.CustomToolbar_monthButton__D8Un6, .CustomToolbar_dayButton__TaeF8{
    border: 1px solid var(--primary-color)
}

.CustomToolbar_monthButton__D8Un6, .CustomToolbar_weekButton__jr6Ac, .CustomToolbar_dayButton__TaeF8{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    height: 28px;
    color: var(--primary-color);
}

.CustomToolbar_dayButton__TaeF8{
    width: 54px;
    border-radius: 5px 0px 0px 5px;
}

.CustomToolbar_weekButton__jr6Ac{
    width: 65px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.CustomToolbar_monthButton__D8Un6{
    width: 69px;
    border-radius: 0px 5px 5px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CalendarComponent/Components/CustomToolbar/CustomToolbar.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,0CAA0C;IAC1C,6CAA6C;AACjD;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".main{\n\n}\n\n.navButton{\n    width:40px;\n    height: 40px;\n    border-radius: 5px;\n    border: 1px solid var(--primary-color);\n    flex-shrink: 0;   \n}\n\n.dateText{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.classesText{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n}\n\n.scheduleButton{\n    padding: 15px 20px 15px 20px;\n    border-radius: 10px;\n    background-color: var(--primary-color);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    color: #FFFFFF;\n}\n\n.monthButton, .dayButton{\n    border: 1px solid var(--primary-color)\n}\n\n.monthButton, .weekButton, .dayButton{\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    height: 28px;\n    color: var(--primary-color);\n}\n\n.dayButton{\n    width: 54px;\n    border-radius: 5px 0px 0px 5px;\n}\n\n.weekButton{\n    width: 65px;\n    border-top: 1px solid var(--primary-color);\n    border-bottom: 1px solid var(--primary-color);\n}\n\n.monthButton{\n    width: 69px;\n    border-radius: 0px 5px 5px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CustomToolbar_main__2tkz+`,
	"navButton": `CustomToolbar_navButton__nHvxQ`,
	"dateText": `CustomToolbar_dateText__z8JGO`,
	"classesText": `CustomToolbar_classesText__sYKwP`,
	"scheduleButton": `CustomToolbar_scheduleButton__yps4V`,
	"monthButton": `CustomToolbar_monthButton__D8Un6`,
	"dayButton": `CustomToolbar_dayButton__TaeF8`,
	"weekButton": `CustomToolbar_weekButton__jr6Ac`
};
export default ___CSS_LOADER_EXPORT___;
