import BackIcon from '../../../icons/BackIcon'
import classes from './HeadingContainer.module.css'

const HeadingContainer = ({heading, className, style, sort, onClick}) => {
    return(
        <div className={`${className} ${classes.wrapper} d-flex align-items-center justify-content-between mb-4`} style={style}>
            <div className={`${classes.innerWrapper} d-flex align-items-baseline gap-3`} onClick={onClick}><BackIcon /><h2>{heading}</h2></div>
            <div className={classes.showTypes}>{sort}</div>
        </div>
    )
}

export default HeadingContainer