import ActivitiesCardContainer from '../../Components/ActivitiesCardContainer/ActivitiesCardContainer'
import BasicCalendar from '../../Components/BasicCalendar/BasicCalendar'
import HorizontalScrollContainer from '../../Components/HorizontalScrollContainer/HorizontalScrollContainer'
import PerformanceCardContainer from '../../Components/PerformanceCardContainer/PerformanceCardContainer'
import BoardIcon from '../../icons/BoardIcon'
import BookPlayIcon from '../../icons/BookPlayIcon'
import DocumentIcon from '../../icons/DocumentIcon'
import EventsIcon from '../../icons/EventsIcon'
import GradeIcon from '../../icons/GradeIcon'
import VideoIcon from '../../icons/VideoIcon'

import classes from './Dashboard.module.css'

const activities = [
    {
        icon: <EventsIcon />,
        title: "Events",
        description: "8 upcoming events this week",
        backgroundColor: "#E8B241"
    },
    {
        icon: <BookPlayIcon />,
        title: "Lessons",
        description: "You have 2 lessons remaining today",
        backgroundColor: "#FA6D6D"
    },
    {
        icon: <DocumentIcon />,
        title: "Resources",
        description: "6 new resources available for you",
        backgroundColor: "#AF80CC"
    },
    {
        icon: <GradeIcon />,
        title: "Grades",
        description: "6 new resources available for you",
        backgroundColor: "#76A3CC"
    }
]

const upcomingLessons = [
    {
        icon: <VideoIcon />,
        type: 'Live lesson',
        name: 'Teacher Name',
        subject: 'English grammar',
        startTime: '8:00',
        endTime: '9:00',
        dateTime: '8AM - 9AM',
        backgroundColor: '#E3F0E7',
        color: '#43B061',
        active: true
    },
    {
        icon: <VideoIcon />,
        type: 'Live lesson',
        name: 'Joseph Myers',
        subject: 'Mathematical Literacy Term 1',
        startTime: '1:00',
        endTime: '2:00',
        dateTime: '8AM - 9AM',
        backgroundColor: '#E3F0E7',
        color: '#43B061',
        active: false
    },
    {
        icon: <BoardIcon />,
        type: 'Interactive lesson',
        name: 'Joseph Myers',
        subject: 'NS & Tech: Matter and Materials',
        startTime: '1:00',
        endTime: '2:00',
        dateTime: '8AM - 9AM',
        backgroundColor: '#F6EEDF',
        color: '#CA9835',
        active: false
    }
]

const performance = [
    {
        title: "Next grade",
        subject: "Biology:",
        date: "15th Feb",
        borderColor: "#76A3CC",
    },
    {
        title: "Assignment",
        subject: "Biology",
        date: "15th Feb - A+ (96%)",
        borderColor: "#F32488",
    },
    {
        title: "Assignment",
        subject: "Biology",
        date: "15th Feb - A+ (96%)",
        borderColor: "#F32488",
    },
    {
        title: "Next grade",
        subject: "Biology:",
        date: "15th Feb",
        borderColor: "#76A3CC",
    }
]

const Dashboard = () => {
    return(
        <div className={classes.main}>
            <HorizontalScrollContainer heading="Heading">
            <div className='row w-100'>
                <div className='col-7'>
                    {upcomingLessons.map((item, i) => (<div className={classes.todayLessonsContainer} key={i}>
                        <div className='d-flex align-items-center gap-3 mb-4'>
                            <div className={`d-flex flex-column align-items-center justify-content-between  ${classes.timeColumn}`} style={{backgroundColor: item.active && "#43B061", color: item.active && "#FFFFFF"}}>
                                <div className={classes.timeText}>{item.startTime}</div>
                                <div className={classes.timeText}>{item.endTime}</div>
                            </div>
                            <div className={classes.detailsColumn} style={{backgroundColor: item.active && "#43B061", color: item.active && "#FFFFFF"}}>
                                <div className='d-flex align-items-center gap-3 mb-2'>
                                    <div className={`${classes.pillContainer} d-flex align-items-center gap-1`} style={{backgroundColor: item.active ? "#FFFFFF" : item.backgroundColor, color: item.color}}>{item.icon} {item.type}</div>
                                    <h6>{item.name}</h6>
                                </div>
                                <h3>{item.subject}</h3>
                                <h6>{item.dateTime}</h6>
                            </div>
                        </div>
                    </div>))}
                </div>
                <div className='col-5'>
                        <BasicCalendar />
                </div>
            </div>    
            </HorizontalScrollContainer>

            <HorizontalScrollContainer heading="Activity">
            {activities.map((item, i) => {
                return(
                    <ActivitiesCardContainer key={i} icon={item.icon} title={item.title} description={item.description} backgroundColor={item.backgroundColor}/>
                )
            })}
            </HorizontalScrollContainer>

            <HorizontalScrollContainer heading="Performance">

            {performance.map((item, i) => {
                return(
                    <PerformanceCardContainer key={i} title={item.title} subject={item.subject} date={item.date} borderColor={item.borderColor}/>
                )
            })}

            </HorizontalScrollContainer>
        </div>
    )
}

export default Dashboard