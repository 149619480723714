// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_main__svIsx{
    padding: 50px 40px 60px 40px;
    height: 100vh;
    overflow: auto;
}

.Login_inputWrapper__5VztC{
    width: 600px;
}

.Login_continueButton__F\\+Auj{
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
    color: #FFFFFF;
    background: var(--primary-color);
    width: 600px;
}

.Login_linkText__0RT2R{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #43B061;
    text-decoration: underline;
}

.Login_titleText__iVwSq{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.Login_errorText__uaEdO{
    color: red;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".main{\n    padding: 50px 40px 60px 40px;\n    height: 100vh;\n    overflow: auto;\n}\n\n.inputWrapper{\n    width: 600px;\n}\n\n.continueButton{\n    width: 100%;\n    padding: 20px 0px;\n    border-radius: 10px;\n    color: #FFFFFF;\n    background: var(--primary-color);\n    width: 600px;\n}\n\n.linkText{\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 18px;\n    color: #43B061;\n    text-decoration: underline;\n}\n\n.titleText{\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n}\n\n.errorText{\n    color: red;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Login_main__svIsx`,
	"inputWrapper": `Login_inputWrapper__5VztC`,
	"continueButton": `Login_continueButton__F+Auj`,
	"linkText": `Login_linkText__0RT2R`,
	"titleText": `Login_titleText__iVwSq`,
	"errorText": `Login_errorText__uaEdO`
};
export default ___CSS_LOADER_EXPORT___;
