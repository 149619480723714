import ChevronIcon from '../../../icons/ChevronIcon'
import CardContainer from '../CardContainer/CardContainer'
import classes from './InfoCard.module.css'

const InfoCard = ({onClick, buttonText, color, backgroundColor, title, titleValue, className}) => {
    return(
        <CardContainer className={`d-flex flex-column justify-content-between ${className} ${classes.main}`} style={{backgroundColor: backgroundColor, color: color}}>
            <h3>{title} <span>{titleValue}</span></h3>
            <div className={`${classes.viewDetailsButton} d-flex gap-2 align-items-center`} onClick={onClick} ><span style={{color: color}}>{buttonText}</span><ChevronIcon color={color}/></div>
        </CardContainer>
    )
}

export default InfoCard